import * as React from 'react';
import { TableCell, TableFooter, TableRow, Typography } from '@mui/material';
import { GridLayout } from 'shared/ui/grid-layout';
import { useSnack } from 'shared/ui/snack';
import { useCashBalanceData } from './cash-balance.hook';
import { CashBalanceDto } from '@sr/dto';
import { useSaveFinalBalance } from './save-final-balance.hook';
import Button from '@mui/material/Button';


const columns = [
  'Расчетный счет', 'Остаток на 1-ое число месяца', 'Поступления за месяц', 'Списания за месяц', 'Остаток на текущее число (на последнее число месяца)', '',
];

const CashBalanceRow = ({ row, year, month }: { row: CashBalanceDto, year: number, month: number }) => {
  const saveBalance = useSaveFinalBalance();


  const handleSaveBalance = async () => {
    await saveBalance({
      month,
      year,
      expenses: row.expenses,
      income: row.income,
      startDateBalanceValue: row.startDateBalanceValue,
      endDateBalanceValue: row.endDateBalanceValue,
      accountId: row.bankAccountId,
    });
  };

  return (
    <TableRow>
      <TableCell align="center">
        {row.bankAccountTitle}
      </TableCell>
      {row.isAvailableForSave ?
        <>
          <TableCell align="center">
            {row.startDateBalanceValue}
          </TableCell>
          <TableCell align="center">
            {row.income}
          </TableCell>
          <TableCell align="center">
            {row.expenses}
          </TableCell>
          <TableCell align="center">
            {row.endDateBalanceValue}
          </TableCell>
        </> :
        <TableCell align="center" colSpan={4}>
          <Typography color="error" variant="subtitle2">Чтобы увидеть остатки, закройте предыдущий месяц</Typography>
        </TableCell>
      }
      <TableCell align="center">
        {!row.isSavedBalance && (month < (new Date()).getMonth() || year < (new Date().getFullYear()) ) &&
          <Button variant="contained"
                  disabled={!row.isAvailableForSave}
                  type="submit"
                  autoFocus
                  onClick={handleSaveBalance}
          >
            Сохранить
          </Button>
        }
        {row?.user &&
          <span>{row.user.secondName} {row.user.firstName} {row.user.middleName} подтвердил</span>
        }
      </TableCell>
    </TableRow>);
};

const CashBalanceTotal = (rows: CashBalanceDto[]) => {
  return (
    <TableFooter>
      <TableRow>
        <TableCell align="center">
          <b>ИТОГО:</b>
        </TableCell>
        <TableCell align="center">
          {rows.reduce((acc, curr) => (curr.isAvailableForSave ? curr.startDateBalanceValue : 0) + acc, 0)}
        </TableCell>
        <TableCell align="center">
          {rows.reduce((acc, curr) => (curr.isAvailableForSave ?curr.income : 0) + acc, 0)}
        </TableCell>
        <TableCell align="center">
          {rows.reduce((acc, curr) => (curr.isAvailableForSave ? curr.expenses : 0) + acc, 0)}
        </TableCell>
        <TableCell align="center">
          {rows.reduce((acc, curr) => (curr.isAvailableForSave ? curr.endDateBalanceValue : 0) + acc, 0)}
        </TableCell>
        <TableCell align="center">
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};

export const CashBalanceGrid = ({ year, month }: { year: number, month: number }) => {
  const { rows, isLoading, error } = useCashBalanceData(year, month);
  const { showSuccess, showError } = useSnack();


  return <GridLayout
    columns={columns}
    items={rows}
    isLoading={isLoading}
    error={error}
    footer={CashBalanceTotal}
    itemRender={(row) => <CashBalanceRow year={year} month={month} key={row.bankAccountId} row={row} />}
    noItemsText="Нет оплаченных счетов за выбранный период" />;
};
