import { extractNestJsErrorMessage, REFETCH_TIME_SPAN } from 'shared/api/rtk-query';
import { invoiceWccApi } from './invoice-wcc.api';
import { CreateInvoiceWccSupplierDto, UpdateInvoiceWccSupplierDto } from '@sr/dto';

export const useInvoiceSupplierWcc = (id: number) => {
  const { data, isLoading, error, refetch } = invoiceWccApi.useGetInvoicesSupplierWccQuery(id,  { refetchOnMountOrArgChange: REFETCH_TIME_SPAN });
  const [ create ] = invoiceWccApi.useCreateInvoiceSupplierWccMutation();
  const [ drop ] = invoiceWccApi.useDropInvoiceSupplierWccMutation();
  const [ update ] = invoiceWccApi.useUpdateInvoiceSupplierWccMutation();

  return {
    dataWcc: data ?? [],
    createInvoiceSupplierWcc: async (dto: CreateInvoiceWccSupplierDto) => {
        return create(dto).unwrap();
        },
    updateInvoiceSupplierWcc: async (dto: UpdateInvoiceWccSupplierDto) => {
        return update(dto).unwrap();
        },
    dropInvoiceSupplierWcc: async (id: number) => {
        return drop(id).unwrap();
        },    
    reload: () => refetch(),
    error: extractNestJsErrorMessage(error),
    isLoading
  };
};