import { useState, Dispatch, SetStateAction } from 'react';
import { Chip, TableCell, TableRow, Checkbox, Typography, Box } from '@mui/material';
import { IPagingData, LeadInfoDto } from '@sr/dto';
import { GridLayout } from 'shared/ui/grid-layout';
import { formatDate } from 'utils/date-format-helpers';
import { LinkToLead } from 'shared/ui/links/link-to-lead';
import { useLeads } from '../leads.hook';
import { LeadsFilter } from '../../../features/lead/leads-filter/leads-filter';
import { useSearchParams } from 'react-router-dom';
import { LinkToCompany } from '../../../shared/ui/links/link-to-company';
import ManagerSpan from 'shared/ui/manager-span';
import { LeadBulkUpdate } from '../../../features/lead/lead-bulk-update/lead-bulk-update';
import { statusCounter } from '../../../utils/status-counter-helper';
import {
  LEAD_STATUS_IN_PROGRESS,
  LEAD_STATUS_NOT_PROCESSED, LEAD_STATUS_WAITING_FOR_DECISION,
  LEADS_STATUSES,
  LeadStatus,
} from '@sr/dto/dist/src/enums/leads';


const columns = ['', 'Дата создания', 'Тема', 'Статус', 'Фирма', 'Менеджер', 'Бюджет', 'Качество'];
const readOnlyColumns = columns.slice(1);

type Props = {
  companyId?: number;
  paging: IPagingData;
  onChangePaging?: (paging: IPagingData) => void;
  readOnly?: boolean;
  customSearchParams?: URLSearchParams;
  withCounts?: boolean;
}

export const LeadsList = ({ paging, onChangePaging, companyId, customSearchParams, readOnly = false, withCounts = false }: Props) => {
  const [searchParams] = useSearchParams();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const { collection, isLoading, error } = useLeads(paging, customSearchParams || searchParams);

  const statuses = statusCounter([LEAD_STATUS_NOT_PROCESSED,LEAD_STATUS_IN_PROGRESS,LEAD_STATUS_WAITING_FOR_DECISION],
    'statusId', collection.meta);

  return (
    <>
      {!readOnly && (
        <LeadsFilter/>
      )}
      {(selectedIds.length > 0 && !readOnly) && (
        <LeadBulkUpdate selectedIds = { selectedIds } />
      )}
      {withCounts && !isLoading && ( <Box display = "flex" gap = {2} my = {2} component = "div" alignItems = "baseline">
        Отображаются данные со статусами
        {statuses.map((item)=> {
          return (
            <Chip
              label={
                <Box display="flex" alignItems="center" gap = {1}>
                  <Typography variant="body2" component="span">
                    {`${LEADS_STATUSES[item.status as LeadStatus] } - `}
                  </Typography>
                  <Typography variant="body2" component="span" sx={{ fontWeight: 'bold' }}>
                    {item.count}
                  </Typography>
                </Box>
              }
            />
          );
        })}
      </Box>)
      }
      <GridLayout
        columns={readOnly ? readOnlyColumns : columns}
        items={collection.items}
        isLoading={isLoading}
        error={error}
        pagingData={paging}
        onChangePaging={onChangePaging}
        totalItemsCount={collection.totalItemsCount}
        noItemsText='Список лидов пуст'
        itemRender={(i) => <LeadRow
          readOnly={readOnly}
          selectedIds = {selectedIds}
          setSelectedIds = {setSelectedIds}
          key={`${i.id} ${i.status}`}
          lead={i}
        />}
      />
    </>);
};


type RowProps = {
    lead: LeadInfoDto,
    selectedIds: number[];
    setSelectedIds: Dispatch<SetStateAction<number[]>>;
    readOnly: boolean;
};

export const LeadRow = (props: RowProps) => {
  const { lead, selectedIds, setSelectedIds, readOnly } = props;

  const onCheckboxChange = (id: number) => {
    setSelectedIds(selectedIds.includes(id) ?
      selectedIds.filter((leadId) => leadId !== id) :
      [...selectedIds, id]);
  };
  return (
    <>
      <TableRow
        key={lead.id}
      >
        {!readOnly && <TableCell width={50} component="th" scope="row">
          <Checkbox checked={selectedIds.includes(lead.id)}
            onChange={() => onCheckboxChange(lead.id)}/>
        </TableCell>}

        <TableCell>
          {formatDate(lead.createdAt)}
        </TableCell>
        <TableCell>
          <LinkToLead lead={lead}/>
        </TableCell>
        <TableCell>{lead.status ? <Chip label={lead.status.label}/> : '-'}</TableCell>
        <TableCell>{lead.companies ? lead.companies.map((item)=> (
          <div key = {`${item.id}`}><LinkToCompany company={item}/></div>
        )) : '-'}</TableCell>
        <TableCell>{lead.manager ? <ManagerSpan person={lead.manager} /> : '-'}</TableCell>
        <TableCell>{lead.budget ? lead.budget.label : '-'}</TableCell>
        <TableCell>{lead.quality ? lead.quality.label : '-'}</TableCell>
      </TableRow>
    </>
  );
};



