import { CreateInvoiceDto, INVOICE_PRINT_NOT_SHOW_DATE, InvoiceDto } from '@sr/dto';
import { VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';
import { FormikErrors } from 'formik';
import { FormData as BasicFormData } from 'features/invoice/invoice-mutable-data-form/basic-invoice.form-tools';

export type SharedFormData = BasicFormData & {
  invoiceNumber: string,
  sellerLegalEntityId: number | null,
  sellerCompanyId?: number | null,
  buyerLegalEntityId: number | null,
};

export const sharedEmptyValues = (): SharedFormData => {
  const start = new Date();
  const end = new Date();
  end.setMonth(start.getMonth() + 1);
  return {
    invoiceNumber: '',
    startDate: start,
    endDate: end,
    sellerLegalEntityId: null,
    buyerLegalEntityId: null,
    managerId: null,
    printFormShowMediaLink: false,
    printFormShowDateViewState: INVOICE_PRINT_NOT_SHOW_DATE,
    invoiceSupplier: false
  };
};


export const sharedValidate = (values: SharedFormData): FormikErrors<SharedFormData> => {
  let validationResult: FormikErrors<SharedFormData> = {};

  if (!values.startDate)
    validationResult.startDate = VALIDATION_REQUIRED_FIELD;

  if (!values.endDate && !values.invoiceSupplier)
    validationResult.endDate = VALIDATION_REQUIRED_FIELD;

  if (!values.sellerLegalEntityId)
    validationResult.sellerLegalEntityId = VALIDATION_REQUIRED_FIELD;

  if (!values.buyerLegalEntityId)
    validationResult.buyerLegalEntityId = VALIDATION_REQUIRED_FIELD;

  return validationResult;
};

export function sharedToDto(formData: SharedFormData) {
  const dto = new CreateInvoiceDto();

  dto.startDate = new Date(formData.startDate);
  dto.endDate = new Date(formData.endDate);
  dto.sellerLegalEntityId = Number(formData.sellerLegalEntityId);
  dto.buyerLegalEntityId = Number(formData.buyerLegalEntityId);
  dto.managerId = Number(formData.managerId);

  return dto;
}

export function sharedFromDto(dto: InvoiceDto): SharedFormData {
  const formData: SharedFormData = {
    invoiceNumber: dto.invoiceNumber,
    startDate: new Date(dto.startDate),
    endDate: new Date(dto.endDate),
    sellerLegalEntityId: dto.seller.id,
    buyerLegalEntityId: dto.buyer.id,
    managerId: dto.manager ? dto.manager.id : null,
    printFormShowDateViewState: dto.printFormShowDateViewState,
    printFormShowMediaLink: dto.printFormShowMediaLink,
  };
  return formData;
}
