import { useTitleHook } from '../../hooks/page-title.hook';
import { Heading } from '../../shared/ui/list-page-heading';
import { Stack, Box } from '@mui/material';
import { LeadsList } from '../../entities/lead/lead-list';
import { DesktopWidget } from '../../components/Mui/desktop-widget';
import { EventsList, useEventsList } from '../../features/events/events-list';
import { InvoicesList } from '../../entities/invoice/list';
import { useStateSelector } from '../../shared/store';
import { Worklog } from '../../widgets/desktop/worklog';
import { useClientInvoices } from '../../entities/client-invoices-list/lib/client-invoices-list.hook';
import { INVOICE_STATUS_DEBT, INVOICE_STATUS_PAID, INVOICE_STATUS_PARTIAL_PAID, IPagingData } from '@sr/dto';
import { LEAD_STATUS_IN_PROGRESS, LEAD_STATUS_NOT_PROCESSED, LEAD_STATUS_WAITING_FOR_DECISION } from '@sr/dto/dist/src/enums/leads';

const eventsSearchParams = 'isImportantOnly=true&isCompletedHidden=true';
const title = 'Рабочий стол';
const desktopPaging: IPagingData = { itemsPerPage: 10, page: 0 };

export const DesktopPage = () => {
  useTitleHook(title);
  const currentUser = useStateSelector((store) => store.auth.user);
  const currentUserId: number | null = currentUser?.id ?? null;
  const invoicesSearchParams = `managerId=${currentUserId}&statusIds=${INVOICE_STATUS_PAID},${INVOICE_STATUS_PARTIAL_PAID},${INVOICE_STATUS_DEBT}`;
  const leadsSearchParams = `managerId=${currentUserId}&statusId=${LEAD_STATUS_NOT_PROCESSED},${LEAD_STATUS_IN_PROGRESS},${LEAD_STATUS_WAITING_FOR_DECISION}`;
  const { collection: invoiceCollection } = useClientInvoices(desktopPaging, invoicesSearchParams);
  const { collection: eventsCollection, isLoading, error } = useEventsList('my', 0, new URLSearchParams(eventsSearchParams));

  return (
    <div>
      <Heading title={title} />
      <DesktopWidget title="Лиды" navigateLink={`/leads/?${leadsSearchParams}`}>
        <LeadsList paging={desktopPaging} readOnly={true} customSearchParams={new URLSearchParams(leadsSearchParams)} withCounts={true} />
      </DesktopWidget>
      <Stack direction="row" alignItems="baseline" justifyContent="space-between" gap={3} my={2}>
        <DesktopWidget title="Рабочее время">{currentUser && currentUserId ? <Worklog user={currentUser} /> : 'Данные недоступны'}</DesktopWidget>
        <DesktopWidget title="Дела" navigateLink={`/events/my?${eventsSearchParams}`} error={error}>
          <Box sx={{ height: '648px' }}>
            <EventsList collection={eventsCollection} isLoading={isLoading} showCompany={true} />
          </Box>
        </DesktopWidget>
      </Stack>
      <DesktopWidget title="Клиентские счета" navigateLink={`/client-invoices/?${invoicesSearchParams}`}>
        <InvoicesList collection={invoiceCollection} paging={desktopPaging} readOnly={true} withCounts={true} />
      </DesktopWidget>
      <DesktopWidget title="Эффективность менеджера">TODO</DesktopWidget>
    </div>
  );
};
