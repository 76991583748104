import { BaseUrlParamsFilter, BaseUrlParamsFilterProps } from '../../../entities/filters/base-url-params-filter';
import { Field } from 'formik';
import { LeadBudgetPickerField } from '../../../shared/ui/formik-fields/lead-budget-picker-field';

export const LeadBudgetParamFilter = ({ queryParamName, label, size = 'medium' }: BaseUrlParamsFilterProps) => {
  return (
    <BaseUrlParamsFilter queryParamName={queryParamName}>
      <Field
        fullWidth
        name={queryParamName}
        label={label}
        clearable={true}
        size={size}
        component={LeadBudgetPickerField}
      />
    </BaseUrlParamsFilter>
  );
};
