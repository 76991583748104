export interface IPagingData {
  itemsPerPage: number;
  page: number;
}


export interface IPagedCollection<TItem> {
  items: Array<TItem>;
  totalItemsCount: number;
  meta?: Array<Record<string, number>>;
}

export const emptyPagedCollection: IPagedCollection<any> = {
  items: [],
  totalItemsCount: 0
};


