export type CompanyTypesEnum = 'client' | 'competitor' | 'supplier' | 'media';

export enum CompanyQueryType {
    SUPPLIERS_ALL = 'suppliers',
    CLIENTS_ALL = 'all',
    CLIENTS_MY = 'my',
    CLIENTS_DEPARTMENT = 'department',
    CLIENTS_FREE_WITH_INVOICES = 'checks',
    CLIENTS_WITH_LEADS = 'lids',
    CLIENTS_FREE = 'free',
    CLIENTS_ON_FIRED_MANAGERS = 'fired',
    CLIENTS_TO_DETACH = 'detachment'
}

export type CompanyTypes = {
    [x in CompanyTypesEnum]: { label: string; value: number };
};

export const COMPANY_TYPE_INFOS: CompanyTypes = {
    client: {label: 'Клиент', value: 1},
    competitor: {label: 'Конкурент', value: 2},
    supplier: {label: 'Поставщик', value: 4},
    media: {label: 'Рекламный носитель', value: 8},
};

export const CompanyTypesList: [x: CompanyTypesEnum] = Object.keys(COMPANY_TYPE_INFOS) as [x: CompanyTypesEnum];

export const isClient = (companyType: number): boolean => {
    return Boolean(companyType & COMPANY_TYPE_INFOS['client'].value);
};

