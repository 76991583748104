import { IsNotEmpty, IsNumber, IsPositive, Max, Min } from 'class-validator';
import { PeriodDto } from './period';

export class InvoiceClosingDataDto {
  invoiceId: number;
  period: PeriodDto;
  managerPercent: number;
  managerCommission: number | null;
  managerId: number;
  legalEntityTax: number;
}

export class SetManagerPercentDto {
  @IsNumber()
  @Max(100)
  @Min(0)
  @IsNotEmpty()
  managerPercent: number;
}

export class SetInvoiceClosingPeriodDto {
  @IsNotEmpty()
  period: PeriodDto;
}

export class CloseInvoiceDto {
  @IsNumber()
  @IsPositive()
  @IsNotEmpty()
  invoiceId: number;
}
