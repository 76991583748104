import { Type, Transform, Exclude } from 'class-transformer';
import { IsString, IsNotEmpty, IsNumber, IsDate, IsOptional, IsInt, IsArray } from 'class-validator';
import { CompanyDto } from './company.dto';
import { UserDto } from './user.dto';
import { DownloadFileDto } from './download-file.dto';
import { CityInfoDto } from './geo/city';
import { MediaTypeBaseDataDto } from './media-type.dto';
import { EventDto } from './events.dto';

export class LeadInfoDto {
  @IsNumber()
  id: number;

  @IsString()
  @IsNotEmpty()
  subject: string;

  @Type(() => LeadStatusDto)
  status: LeadStatusDto;

  @Type(() => LeadBudgetDto)
  budget: LeadBudgetDto;

  @Type(() => LeadQualityDto)
  quality: LeadQualityDto;

  manager?: UserDto;

  meta?: Array<Record<string, number>>;

  @Type(() => CompanyDto)
  company: CompanyDto;

  @Type(() => Array<CompanyDto>)
  companies?: CompanyDto[];

  @IsDate()
  @Type(() => Date)
  createdAt: Date;
}

export class CreateLeadDto {
  @IsString()
  @IsNotEmpty()
  subject: string;

  // @Type(() => Number)
  statusId: number;

  @IsString()
  @IsNotEmpty()
  content: string;

  budgetId?: number;

  @IsNumber()
  @IsOptional()
  qualityId?: number;

  emailId?: number;

  atsEntryId?: string;

  @Transform((value) => {
    return value.value <= 0 ? undefined : value;
  })
  @IsNumber()
  @IsOptional()
  userId?: number;
}

export class UpdateLeadPropsDto {
  id: number;
  budgetId?: number;
  qualityId?: number;
}

export class LeadDto extends CreateLeadDto {
  id: number;
  status: LeadStatusDto;
  budget: LeadBudgetDto;
  quality: LeadQualityDto;
  company: CompanyDto;

  @IsDate()
  @Type(() => Date)
  createdAt: Date;

  @Type(() => Array<CompanyDto>)
  companies?: CompanyDto[];

  manager?: UserDto;
  events: EventDto[];
}

export class LeadStatusDto {
  id: number;
  label: string;
}

export class LeadBudgetDto {
  id: number;
  label: string;
}

export class LeadQualityDto {
  id: number;
  label: string;
}

export class LeadMediaplanDto {
  id: number;
  cityId: number | null;
  city: CityInfoDto | null;
  mediaTypeId: number | null;
  mediaType: MediaTypeBaseDataDto | null;
  offerFile: DownloadFileDto | null;
  createdBy: UserDto | null;
  createdAt: string;
}

export class LeadMediaplanFilter {
  @IsOptional()
  @IsInt()
  cityId?: number;

  @IsOptional()
  @IsInt()
  mediaTypeId?: number;
}

export class UpdateLeadMediaplanDto {
  cityId: number | null;
  mediaTypeId: number | null;
  offerFile: File | null | undefined;
}

export class LeadBulkUpdateDto {
  @IsArray()
  ids: number[];

  @IsOptional()
  budgetId?: number;

  @IsOptional()
  qualityId?: number;

  @IsOptional()
  statusId?: number;

  @IsOptional()
  managerId?: number;
}
