import { useContext, useState } from 'react';
import { InvoiceDto, WccDto } from '@sr/dto';
import Delete from '@mui/icons-material/Delete';
import { Button, IconButton, Paper, Stack } from '@mui/material';
import { formatDate } from 'utils/date-format-helpers';
import { useSnack } from 'shared/ui/snack';
import PriceSpan from 'shared/ui/price-span';
import { WccSupplierInvoiceDialog } from './wcc-dialog/wcc-dialog-invoice-supplier';
import { FormData } from 'entities/wcc/wcc-dialog/wcc-dialog-invoice-supplier';
import { useInvoiceSupplierWcc } from 'features/invoice/invoice-wcc/invoice-wcc.hook';
import { Link } from 'shared/ui/link-base';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { WccButton } from './wcc-button';

type Props = {
  initialWcc?: WccDto | null
  invoice: InvoiceDto
}

export function WccSupplierInvoiceButton({ initialWcc, invoice }: Props) {
  const { showSuccess, showError } = useSnack();
  const [wccDialogOpen, setWccDialogOpen] = useState(false);
  const { dataWcc, createInvoiceSupplierWcc,error, isLoading, reload: wccReload } = useInvoiceSupplierWcc(invoice.id);

  let total=0
  dataWcc.map(x=>total+=x.amount) 

  const handleWccInvoice = async (data: FormData) => {
    return createInvoiceSupplierWcc({invoiceId: invoice.id, date: new Date(data.date), wccNumber: data.wccNumber, amount: Number(data.amount)})
    .then(() => {
        showSuccess('Документ закрытия успешно создан')
        setWccDialogOpen(false)
        wccReload()
    })
    .catch(e => showError(`Ошибка создания документа закрытия: ${e.data.message}`));
        };  
    return( 
        <>
        { 
            (dataWcc.length == 0) ?
            <>
                <Button onClick={() => setWccDialogOpen(true)}>Сформировать</Button>
                <WccSupplierInvoiceDialog
                open={wccDialogOpen}
                onClose={() => setWccDialogOpen(false)}
                onSubmit={handleWccInvoice}
                /> 
            </> 
            : 
            <WccButton invoiceId={invoice.id} initialWcc={dataWcc[0]}/>
        }    
        </>
    )
}