import { Divider, Typography, useTheme } from '@mui/material';
import { LegendList, LegendItem } from 'shared/ui/legend-list';
import PriceSpan from 'shared/ui/price-span';
import { PaidProgress } from 'shared/ui/progress-bar';
import { CardPaper } from 'shared/ui/card-paper';
import { InvoiceReportTotals } from './invoice-report-rows';
import { getProfitabilityRangeColor } from './lib';
import PercentageInput from 'shared/ui/form-fields/percentage.input';
import { useSetLegalEntityTax } from './legal-entity-tax.hook';
import { INVOICE_STATUS_CLOSED, InvoiceDto } from '@sr/dto';

type ProfitProps = {
  profits: InvoiceReportTotals
  showProfits: boolean,
  invoice: Pick<InvoiceDto, 'status' | 'legalEntityTax' | 'id'>
};

export const ProfitCard = ({ profits, showProfits, invoice }: ProfitProps) => {
  const { setLegalEntityTax, isLoading } = useSetLegalEntityTax(invoice.id);
  const taxAmount = profits.buyerRowsTotal * invoice.legalEntityTax / 100;
  const ramaining = profits.buyerRowsTotal - (profits.buyerPartialPaidTotal ?? 0);
  const profitability = profits.buyerRowsTotal !== 0 ? (100 * (profits.totalProfitByPurchases - taxAmount) / profits.buyerRowsTotal) : undefined;
  const theme = useTheme();

  const handleTaxPercentageChange = async(percent: number) => {
    await setLegalEntityTax(percent);
  };
  
  return (
    <CardPaper>
      <LegendList>
        <PaidProgress
          variant="determinate"
          value={100 * (profits.buyerRowsTotal ? ((profits.buyerPartialPaidTotal ?? 0) / profits.buyerRowsTotal) : 0)}
        />
        <LegendItem title="Сумма:" value={<PriceSpan price={profits.buyerRowsTotal} />} />
        <LegendItem title="Оплачено:" value={<PriceSpan price={profits.buyerPartialPaidTotal ?? 0} />} />
        <LegendItem title="Долг:" value={<PriceSpan price={ramaining} />} />
        {showProfits &&
          <>
            <LegendItem title="Налог:" value={
              <PercentageInput initialValue={invoice.legalEntityTax} readOnly={isLoading || invoice.status === INVOICE_STATUS_CLOSED} onChange={handleTaxPercentageChange}/>
            } />
            <Divider sx={{ p: 1 }} />
            <LegendItem title="Прибыль по заявкам:" value={<PriceSpan price={profits.totalProfitByPurchases} coloring />} />
            <LegendItem title="Сумма налога:" value={<PriceSpan price={taxAmount} />} />
            <LegendItem title="Чистая прибыль:" value={<PriceSpan price={profits.totalProfitByPurchases - taxAmount} />} />
            <LegendItem title="Итого расходы:" value={<PriceSpan price={profits.sellerrRowsTotal ?? 0} showZeroKop/>} />
            <LegendItem title="Оплачено расходов:" value={<PriceSpan price={profits.sellerPartialPaidTotal ?? 0} showZeroKop/>} />
            <LegendItem title="Рентабельность:" value={
              profitability !== undefined ?
                <Typography variant='h6' fontWeight={500} component='span' color={getProfitabilityRangeColor(profitability, theme)}>
                  {profitability.toFixed(0)}%
                </Typography>
                : '-'
            } />

          </>}
      </LegendList >
    </CardPaper>
  );
};
