import { SignerDto, CreateSignerDto, UpdateSignerDto } from '@sr/dto';
import { FormData } from 'components/Companies/Signers/signers.form';
import { plainToInstance } from 'class-transformer';
import { deleteSigner, getSignersList, postSigner, putSigner } from 'shared/api/signers';
import { useRemoteCollection } from 'utils/remote-collection.hook';
import { useCallback } from 'react';
import { fileFieldToDto } from '../utils/form-tools';

export const useSignersList = (legalEntityId?: number) => {
  const { collection, addItem, updateItem, removeItem } = useRemoteCollection<SignerDto, CreateSignerDto, UpdateSignerDto>({
    fetchHandler: useCallback(() => {
      if (legalEntityId === undefined || isNaN(legalEntityId)) {
        return Promise.resolve([]);
      }

      return getSignersList(legalEntityId);
    }, [legalEntityId]),
    postHandler: postSigner,
    updateHandler: putSigner,
    deleteHandler: deleteSigner,
  });


  const addSigner = async (formData: FormData) => {
    const dto: CreateSignerDto = {
      ...formData,
      signature: fileFieldToDto(formData.signature)!,
    }
    dto.legalEntityId = legalEntityId!;
    return addItem(dto);
  };

  const updateSigner = async (id: number, formData: FormData) => {
    const dto: UpdateSignerDto = {
      id: id,
      ...formData,
      signature: fileFieldToDto(formData.signature)!,
    }
    return updateItem(id, dto);
  };


  return {
    collection,
    addSigner,
    updateSigner,
    removeSigner: removeItem
  };
};



