import { Box, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination as MuiPagination, TableRow, TableFooter } from '@mui/material';
import { TableCellProps } from '@mui/material';
import { IPagingData } from '@sr/dto';
import { ElementType, ReactNode } from 'react';
import { ErrorBanner } from './error-banner';
import { LoadingBanner } from './loading-banner';

export const ITEMS_PER_PAGE_OPTIONS = [10, 25, 50, 100];
export const DEFAULT_ITEMS_PER_PAGE = 25;
export const DEFAULT_PAGINATION: IPagingData = {
  page: 0,
  itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
};

export type HeadCell = {
  label: ReactNode;
  align?: TableCellProps['align'];
  padding?: TableCellProps['padding'];
  color?: string;
  additionalIcon?: ReactNode;
};

export type HeadCellsCollection = Array<string | HeadCell>;

export type Pagination = {
  page: number;
  onChangePage: (page: number) => void;
  itemsPerPage: number;
  onChangeItemsPerPage: (value: number) => void;
  totalItemsCount: number;
};

type Props<T> = {
  tableOptions?: {
    component: ElementType<any>;
  };
  columns: HeadCellsCollection;
  items: Array<T>;
  isLoading: boolean;
  error?: string;
  itemRender: (arg: T) => ReactNode;
  newItemRowRender?: () => ReactNode;
  showNewItemRow?: boolean;
  noItemsText: string;
  footer?: (items: Array<T>) => ReactNode;
  pagingData?: IPagingData;
  totalItemsCount?: number;
  onChangePaging?: (paging: IPagingData) => void;
};

export function GridLayout<T>(props: Props<T>) {
  const { items, isLoading, error, itemRender, newItemRowRender, noItemsText, columns, showNewItemRow, pagingData, onChangePaging, totalItemsCount, tableOptions, footer } = props;

  const component = tableOptions?.component || Paper;

  const showNewItem = showNewItemRow || newItemRowRender !== undefined;

  // TODO: внести баннер с ошибкой в FullSpanRow
  if (error)
    return (
      <Container>
        <ErrorBanner errorMessage={error} />
      </Container>
    );

  return (
    <>
      <TableContainer component={component}>
        {/* <TablePagination paging={pagingData} onChange={onChangePaging} totalItemsCount={totalItemsCount} /> */}
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              {columns.map((header, idx) =>
                typeof header === 'string' ? (
                  <TableCell key={idx}>{header}</TableCell>
                ) : (
                  <TableCell key={idx} align={header.align} padding={header.padding} sx={{ backgroundColor: header.color ? header.color : 'inherit' }}>
                    {header.label} {header.additionalIcon ? header.additionalIcon : null}
                  </TableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.length === 0 ? (
              isLoading ? (
                <FullSpanRow columnsCount={columns.length} content={<LoadingBanner />} />
              ) : (
                <FullSpanRow columnsCount={columns.length} content={noItemsText} />
              )
            ) : (
              items.map(itemRender)
            )}
            {showNewItem && newItemRowRender && newItemRowRender()}
          </TableBody>
          {footer && <TableFooter>{footer(items)}</TableFooter>}
        </Table>
        {pagingData && onChangePaging && totalItemsCount !== undefined && <TablePagination paging={pagingData} onChange={onChangePaging} totalItemsCount={totalItemsCount} />}
      </TableContainer>
    </>
  );
}

const FullSpanRow = ({ columnsCount, content }: { columnsCount: number; content: ReactNode }) => {
  return (
    <TableRow>
      <TableCell colSpan={columnsCount}>
        <Box sx={{ textAlign: 'center', marginX: 'auto' }}>{content}</Box>
      </TableCell>
    </TableRow>
  );
};

type PagingProps = {
  totalItemsCount: number;
  paging: IPagingData;
  onChange: (paging: IPagingData) => void;
};

const TablePagination = ({ paging, onChange, totalItemsCount }: PagingProps) => {
  return (
    <MuiPagination
      rowsPerPageOptions={ITEMS_PER_PAGE_OPTIONS}
      component="div"
      count={totalItemsCount}
      rowsPerPage={paging.itemsPerPage}
      page={totalItemsCount === 0 ? 0 : paging.page}
      showFirstButton
      showLastButton
      onPageChange={(event: any, newPage: number) =>
        onChange({
          page: newPage,
          itemsPerPage: paging.itemsPerPage,
        })
      }
      onRowsPerPageChange={(event: any) =>
        onChange({
          page: paging.page,
          itemsPerPage: Number(event.target.value),
        })
      }
    />
  );
};
