import { SyntheticEvent, useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { TextFieldProps, fieldToTextField } from 'formik-mui';


type CollectionItem = {
  id: number;
  label: string
}
type CollectionAutocompleteProps = {
  items: CollectionItem[];
  isLoading: boolean;
  clearable?: boolean
  size?: 'small' | 'medium' | undefined;
} & TextFieldProps

export const CollectionAutocomplete = (props: CollectionAutocompleteProps) => {
  const {
    form: { setFieldValue, initialValues },
    field: { name },
    size =  undefined,
  } = props;

  const {
    items,
    isLoading,
    clearable = false,
    ...textFieldProps } = props;

  const initialId: number | null = initialValues[name] ?? null;

  const [selectedId, setSelectedId] = useState<number | null>(initialId);

  const onChange =
    (_event: SyntheticEvent<Element, Event>, newValue: {id: number } | null) => {
      if (!newValue && !clearable) return;
      setSelectedId(newValue ? newValue.id : null);
    };

  const [ open, setOpen ] = useState(false);


  const loading = open && items.length === 0;

  useEffect(() => {
    setFieldValue(name, selectedId ? selectedId : null);
  }, [selectedId, setFieldValue, name]);

  const selectedValue: CollectionItem | null = items.find((x:CollectionItem) => x.id === selectedId) ?? null;

  return (
    <Autocomplete
      fullWidth
      value={ selectedValue}
      options={items}
      disabled={props.disabled}
      disableClearable={!clearable}
      open={open}
      size={size}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.label}`}
      loading={loading}
      loadingText={'Загрузка...'}
      noOptionsText={'Ничего не найдено'}
      renderInput={
        (params) => <TextField
          {...fieldToTextField(textFieldProps)}
          name={name}
          {...params}
          disabled={props.disabled}
          label={textFieldProps.label}
          placeholder="Выбрать"/>
      }
    />);
};


