import { IPagingData, LeadBulkUpdateDto } from '@sr/dto';
import { extractNestJsErrorMessage }         from '../../shared/api/rtk-query';
import { leadsListApi }                      from './leads-list.api';

export const useLeads = (paging: IPagingData, params: URLSearchParams) => {
  const { data, isLoading, error } = leadsListApi.useGetLeadsListQuery({ paging, filter: params?.toString() ?? '' });
  return {
    collection: { items: data?.items ?? [], totalItemsCount: data?.totalItemsCount ?? 0, meta: data?.meta ?? [] },
    isLoading,
    error: extractNestJsErrorMessage(error),
  };
};

export const useBulkUpdateLeads =  () => {
  const [update, { isLoading, error }] = leadsListApi.useBulkUpdateLeadsMutation();
  return async (formData: LeadBulkUpdateDto)=> {
    return update({ ...formData }).unwrap();
  };
};
