export type ContractStatus = 1 | 2 | 3 | 4 | 5;

export const CONTRACT_STATUS_READY = 1;

export const CONTRACT_STATUSES: { [key in ContractStatus]: string } = {
  1: 'Готов',
  2: 'Отправлен',
  3: 'Загружен скан',
  4: 'Подтвержден скан',
  5: 'Получен оригинал',
}

export const CONTRACT_STATUSES_KEYS = Object.keys(CONTRACT_STATUSES).map(k => Number(k) as ContractStatus);

type CLIENT = 1;
type SUPPLIER = 2;

export const CONTRACT_TYPE_CLIENT: CLIENT = 1;
export const CONTRACT_TYPE_SUPPLIER: SUPPLIER = 2;
export type ContractType = CLIENT | SUPPLIER;