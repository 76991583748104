import { memo } from 'react';
import { LeadStatusPickerField }  from '../../../shared/ui/formik-fields/lead-status-picker-field';
import { LeadBudgetPickerField }  from '../../../shared/ui/formik-fields/lead-budget-picker-field';
import { LeadQualityPickerField } from '../../../shared/ui/formik-fields/lead-quality-picker-field';
import { Form, Formik, Field } from 'formik';
import { Stack, Button, Typography } from '@mui/material';
import { CardPaper } from '../../../shared/ui/card-paper';
import { BulkFormData } from '../create/form';
import { UserPickerField } from '../../user/user-picker';
import { toFormData } from '../../../shared/api/axios';
import { extractNestJsErrorMessage } from '../../../shared/api/rtk-query';
import { useSnack } from '../../../shared/ui/snack';
import { useBulkUpdateLeads } from '../../../entities/lead/leads.hook';

type InitialValues = {
  statusId: number | string ;
  budgetId: number | string ;
  qualityId: number | string;
  userId: number | string ;
}
const initialValues:InitialValues = {
  statusId: '',
  budgetId: '',
  qualityId: '',
  userId: '',
};
const LeadUpdate = ({ selectedIds }: {selectedIds: number[]}) => {
  const { showError, showSuccess } = useSnack();
  const update = useBulkUpdateLeads();

  const handleSubmit = async (data: BulkFormData) => {
    const formData = { ids: selectedIds, ...toFormData(data) };
    update(formData)
      .then(() => {
        showSuccess('Данные успешно обновлены');
      })
      .catch(e => showError(`Ошибка обновления данных: ${extractNestJsErrorMessage(e)}`));
  };

  return (
    <CardPaper sx={{ mb: 2 }}>
      <Typography variant = "body1" my = {2} fontWeight={500}>Внести массовое изменение</Typography>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        <Form>
          <Stack direction="row" spacing={2}>
            <Field component={LeadStatusPickerField} name="statusId" label="Статус"/>
            <Field component={LeadBudgetPickerField} name="budgetId" label="Бюджет"/>
            <Field label='Качество' component={LeadQualityPickerField} name="qualityId"/>
            <Field label='Менеджер' component={UserPickerField} name="userId" clearable = {true} withNonSelectOption = {true}/>
            <Button
              sx = {{ minWidth: '120px' }}
              color="warning"
              variant="contained"
              type='submit'
              autoFocus
            >
              Применить
            </Button>
          </Stack>
        </Form>
      </Formik>
    </CardPaper>
  );
};

export const LeadBulkUpdate = memo(LeadUpdate);
