import { Button, ButtonProps } from '@mui/material';

type ToggleButtonProps = {
  active: boolean;
  activeCaption: string;
  inactiveCaption: string;
  onClick: () => void;
  color: 'inherit' | 'primary' | 'error' | 'secondary' | 'info' | 'success' | 'warning' | undefined;
};

export const ToggleButton = ({ active, activeCaption, inactiveCaption, color, ...props }:ToggleButtonProps & ButtonProps) => {
  const variant = !active ? 'contained' : 'outlined';
  const text = active ? activeCaption : inactiveCaption;
  return (
    <Button
      {...props}
      variant={variant}
      onClick={props.onClick}
      color={color}
    >
      {text}
    </Button>);
};
