import { useSearchParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { EventsList, useEventsList } from '.';
import { ErrorBanner } from 'shared/ui/error-banner';


export const EventsListTab = ({ tab }: {tab:string}) => {
  const [ currentPage, setCurrentPage] = useState(0);
  const [ searchParams] = useSearchParams();
  const paramsRef = useRef<URLSearchParams>();

  useEffect(() => {
    setCurrentPage(0);
  }, [searchParams]);

  useEffect(() => {
    paramsRef.current = searchParams;
  }, [currentPage, searchParams]);

  const { collection, isLoading, error } = useEventsList(tab, currentPage, paramsRef.current);
  if (error)
    return <ErrorBanner errorMessage={error} />;

  return <>
    <EventsList
      collection={collection}
      isLoading={isLoading}
      loadMore={ () => { setCurrentPage(x=> x + 1 ) }}
      showCompany={true}
    />
  </>;
};
