import { getCompanyTypeArray, typeFromCheckBoxes } from 'entities/company/company-type-field/company-type-helpers';
import { FormValues } from './company-dialog.form';
import { CompanyDialogCore, DialogProps } from './company-dialog';
import { CompanyDto, EMPTY_CONTACTS, UpdateCompanyDto } from '@sr/dto';

interface EditDialogProps extends DialogProps<UpdateCompanyDto> {
  company: CompanyDto;
}

export const EditCompanyDialog = (props: EditDialogProps) => {
  const { open, onClose, onSubmit, company } = props;

  const handleSubmit = async (values: FormValues) => {
    const updateCompanyData: UpdateCompanyDto = {
      id: company.id,
      name: values.name,
      type: typeFromCheckBoxes(values.companyType),
    };

    if (values.cityId) {
      updateCompanyData.cityId = values.cityId;
    }

    await onSubmit(updateCompanyData);
    onClose();
  };

  const provideFormInitialValues = (): FormValues => {
    return {
      name: company.name,
      companyType: getCompanyTypeArray(company.type).map((x) => x.value.toString()),
      cityId: company.cityId,
      companyContacts: EMPTY_CONTACTS,
    };
  };

  return <CompanyDialogCore open={open} onClose={onClose} title="Редактирование фирмы" formInitialValues={provideFormInitialValues} handleSubmit={handleSubmit} />;
};
