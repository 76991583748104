import { DownloadFileDto, fromDateString, UpdateInvoiceWccSupplierDto, UpdateWccDto, WccDto, WccStatus } from '@sr/dto';
import { FormTools, VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';
import { FormikErrors } from 'formik';
import { format } from 'date-fns';


export type FormValues = {
  amount: number,
  date: Date | '' | string,
  status: WccStatus,
  wccNumber: string | '',
  aktFile: File | null | undefined,
  updFile: File | null | undefined,
  otherFiles: File[] | [] | undefined
};

export const formTools: FormTools<WccDto, void, UpdateInvoiceWccSupplierDto, FormValues> = {
  toUpdateDto: (id, formData) => ({
    id: id,
    status: formData.status as WccStatus,
    date: formData.date,
    amount: Number(formData.amount),
    wccNumber: formData.wccNumber,
    aktFile: formData.aktFile,
    updFile: formData.updFile,
    otherFiles: formData.otherFiles
  }),

  toCreateDto: formData => { },

  fromDto: (dto) => ({
    amount: dto.amount,
    status: dto.status,
    date: new Date(dto.date),
    wccNumber: dto.wccNumber,
    aktFile: dto.aktFile ?? null,
    updFile: dto.updFile ?? null,
    otherFiles: dto.otherFiles ?? []
  }),

  validate: (values: FormValues): FormikErrors<FormValues> => {
    let validationResult: FormikErrors<FormValues> = {};

    if (!values.wccNumber)
      validationResult.wccNumber = VALIDATION_REQUIRED_FIELD;

    if (!values.date)
      validationResult.date = VALIDATION_REQUIRED_FIELD;
    if (isNaN(Number(values.amount)))
      validationResult.amount = 'Допустимы только числовые значения';

    return validationResult;
  },

  emptyValues: () => ({
    status: WccStatus.READY,
    amount: 0,
    date: new Date(),
    wccNumber: '',
    aktFile: null,
    updFile: null,
    otherFiles: [],
  })
};
