import { addYears, endOfYear, format, getMonth } from 'date-fns';
import { FormikErrors } from 'formik';

export interface FormValues {
  name: string;
  contractStartDate: Date;
  contractEndDate: Date;
  managerId: number;
  attachmentFileId: number;
  status: number;
  type?: number;
  sellerLegalEntityId: number;
  sellerBankDetailsId: number
  sellerSignerId: number;
  buyerLegalEntityId: number;
  buyerBankDetailsId: number;
  buyerSignerId: number;
  specialConditions?: string;
}

const now = new Date();

export const initialValues: FormValues = {
  name: '',
  contractStartDate: now,
  contractEndDate: getMonth(now) !== 11 ? endOfYear(now) : endOfYear(addYears(now, 1)),
  managerId: NaN,
  attachmentFileId: NaN,
  status: 1,
  type: NaN,
  sellerLegalEntityId: NaN,
  sellerBankDetailsId: NaN,
  sellerSignerId: NaN,
  buyerLegalEntityId: NaN,
  buyerBankDetailsId: NaN,
  buyerSignerId: NaN,
  specialConditions: undefined,
};

export const validate = (values: FormValues) => {
  const requiredMsg = 'Поле обязательно';

  const errors: FormikErrors<FormValues> = {};

  if (!values.sellerLegalEntityId) errors.sellerLegalEntityId = requiredMsg;
  if (!values.sellerBankDetailsId) errors.sellerBankDetailsId = requiredMsg;
  if (!values.sellerSignerId) errors.sellerSignerId = requiredMsg;

  if (!values.buyerLegalEntityId) errors.buyerLegalEntityId = requiredMsg;
  if (!values.buyerBankDetailsId) errors.buyerBankDetailsId = requiredMsg;
  if (!values.buyerSignerId) errors.buyerSignerId = requiredMsg;

  if (!values.managerId) errors.managerId = requiredMsg;



  // if (!isNumeric(values.bik)) errors.bik = 'БИК может содержать только цифры';
  // else if (values.bik.length !== 9) errors.bik = 'БИК должен содержать 9 цифр';

  // if (!values.accountNumber) errors.accountNumber = requiredMsg;
  // else if (!isNumeric(values.accountNumber)) errors.accountNumber = 'Номер счета может содержать только цифры';
  // else if (values.accountNumber.length !== 20) errors.accountNumber = 'Номер счета должен содержать 20 цифр';

  // if (!values.corrAccountNumber) errors.corrAccountNumber = requiredMsg;
  // else if (!isNumeric(values.corrAccountNumber))
  //   errors.corrAccountNumber = 'Номер корр. счета может содержать только цифры';
  // else if (values.corrAccountNumber.length !== 20)
  //   errors.corrAccountNumber = 'Номер корр. счета должен содержать 20 цифр';

  return errors;
};
