import { Box, Stack } from '@mui/material';
import { CardPaper } from '../../../shared/ui/card-paper';
import { ManagerUrlParamsFilter } from '../../../entities/filters/manager-url-params-filter';
import { LeadQualityParamFilter } from './lead-quality-param-filter';
import { DateUrlParamsFilter } from '../../../entities/filters/date-url-params-filter';
import { LeadStatusParamFilter } from './lead-status-param-filter';
import { LeadBudgetParamFilter } from './leads-budget-param-filter';


export const LeadsFilter = () => {
  return (
    <CardPaper sx={{ mb: 2 }}>
      <Stack sx={{ mb: 2 }}>
        <Stack direction="row" spacing={2}>
          <Box sx={{ flexBasis: 300, flexGrow: 1 }}>
            <LeadBudgetParamFilter queryParamName={'budgetId'}/>
          </Box>
          <Box sx={{ flexBasis: 300,flexGrow: 1 }}>
            <LeadQualityParamFilter queryParamName={'qualityId'}/>
          </Box>
          <Box sx={{ flexBasis: 300 ,flexGrow: 1 }}>
            <LeadStatusParamFilter queryParamName={'statusId'} multi = {true}/>
          </Box>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2} >
        <Box sx={{ flexBasis: 300 ,flexGrow: 1 }}>
          <ManagerUrlParamsFilter queryParamName='managerId' withNonSelect={true}/>
        </Box>
        <Box sx={{ flexBasis: 300, flexGrow: 1 }}>
          <DateUrlParamsFilter queryParamName={'fromDate'} label="Создан с"/>
        </Box>
        <Box sx={{ flexBasis: 300, flexGrow: 1 }}>
          <DateUrlParamsFilter queryParamName={'toDate'} label="Создан по"/>
        </Box>
      </Stack>
    </CardPaper>
  );
};
