export const getCurrentAndPreviousMonthYear = ():
  { currentMonth: number; currentYear: number; previousMonth: number; previousYear: number } => {

  const now = new Date();
  const currentMonth = now.getMonth();
  const currentYear = now.getFullYear();

  let previousMonth: number;
  let previousYear: number;

  if (currentMonth === 0) {
    previousMonth = 11;
    previousYear = currentYear - 1;
  } else {
    previousMonth = currentMonth - 1;
    previousYear = currentYear;
  }

  return {
    currentMonth,
    currentYear,
    previousMonth,
    previousYear
  };
};
