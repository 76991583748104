import { Stack, TableCell, TableRow } from '@mui/material';
import { INVOICE_TYPE_CLIENT, InvoiceDto, InvoiceReportRowDto } from '@sr/dto';
import { LinkToInvoice } from 'entities/client-invoices-list/ui/link-to-invoice';
import { GridLayout, HeadCellsCollection } from 'shared/ui/grid-layout';
import { LinkToPurchase } from 'shared/ui/links/link-to-purchase';
import { LinkToMedia } from 'shared/ui/links/link-to-media';
import { formatDatesAndPeriod } from 'utils/date-format-helpers';
import PriceSpan from 'shared/ui/price-span';
import { ReactNode } from 'react';
import { ProfitabilityBadge } from './profitability-badge';

export type InvoiceReportTotals = {
  buyerRowsTotal: number,
  sellerrRowsTotal?: number,

  buyerPartialPaidTotal?: number,
  sellerPartialPaidTotal?: number,

  totalProfitByPayments?: number,
  totalProfitByPurchases: number,
}

const SupplierInvoiceColums: HeadCellsCollection = ['Заявка', 'Услуга', 'Количество', 'Период',
  { label: 'Стоимость', align: 'right' },
  { label: 'Оплаты', align: 'right' }];

const ClientInvoiceColums: HeadCellsCollection = [
  ...SupplierInvoiceColums,
  // { label: 'Прибыль по платежкам', align: 'right' },
  { label: 'Прибыль по заявкам', align: 'right' }];

type Props = {
  invoice: InvoiceDto
  rows: InvoiceReportRowDto[]
  totals: InvoiceReportTotals
}
export const InvoiceReportRows = ({ rows, totals, invoice }: Props) => {


  return (
    <>
      <GridLayout
        columns={invoice.type === INVOICE_TYPE_CLIENT ? ClientInvoiceColums : SupplierInvoiceColums}
        items={rows}
        isLoading={false}
        // error={purchases.error}
        itemRender={row => (<ReportRow key={row.buyerRow.id} row={row} isClientInvoice={invoice.type === INVOICE_TYPE_CLIENT} />)}
        noItemsText='Нет заявок'
        footer={() => <FooterRow totals={totals} isClientInvoice={invoice.type === INVOICE_TYPE_CLIENT} />}
      />
    </>
  );
};

const ReportRow = ({ isClientInvoice, row }: { isClientInvoice: boolean, row: InvoiceReportRowDto; }) => {
  const linkedPurchase = row.purchase;
  const buyerRow = row.buyerRow;
  const sellerRow = row.sellerRow;
  const sellerInvoice = row.sellerInvoice;

  return (
    <TableRow>
      <TableCell>
        {linkedPurchase ? <LinkToPurchase purchase={linkedPurchase} /> : '-'}
      </TableCell>
      <TableCell>
        <CellLayout
          top={
            <>
              {buyerRow.description}
              {buyerRow.media && <LinkToMedia media={buyerRow.media} />}
            </>}
          bottom={sellerRow
            ? <>
              {sellerRow.description}
              {sellerInvoice && <> (Счет: <LinkToInvoice invoice={sellerInvoice} />)</>}
            </>
            : '-'}
        />
      </TableCell>

      <TableCell>
        <CellLayout
          top={<>{buyerRow.amount} шт</>}
          bottom={sellerRow?.amount ? sellerRow.amount + ' шт' : '-'} />
      </TableCell>
      <TableCell>
        <CellLayout
          top={formatDatesAndPeriod(buyerRow.startDate, buyerRow.endDate)}
          bottom={sellerRow ? formatDatesAndPeriod(sellerRow.startDate, sellerRow.endDate) : '-'} />
      </TableCell>

      <TableCell align="right">
        <CellLayout
          top={<PriceSpan size='small' price={buyerRow.priceDiscount} />}
          bottom={sellerRow ? <PriceSpan size='small' price={sellerRow.priceDiscount} /> : '-'}
        />
      </TableCell>

      <TableCell align="right">
        <CellLayout
          top={buyerRow.paidAmount && <PriceSpan size='small' price={buyerRow.paidAmount} isApproximate showZeroKop />}
          bottom={
            sellerRow?.paidAmount
              ? <PriceSpan isApproximate size='small' price={sellerRow.paidAmount} showZeroKop />
              : '-'}
        />
      </TableCell>
      {/* {isClientInvoice && <TableCell align="right">
        {row.profitByPayments && <PriceSpan isApproximate price={row.profitByPayments} zeroAsDash showZeroKop coloring />}
      </TableCell>
      } */}
      {isClientInvoice &&
        <TableCell align="right">
          <ProfitWithProfitabilty price={row.buyerRow.priceDiscount} profit={row.profitByPurchases} />
        </TableCell>
      }
    </TableRow >);
};

const FooterRow = ({ totals, isClientInvoice }: { totals: InvoiceReportTotals, isClientInvoice: boolean }) => {
  return (
    <>
      <TableRow sx={{ borderBottom: 'none' }}>
        <TableCell colSpan={4} align='right'>Итого доходы:</TableCell>
        <TableCell align="right">
          <PriceSpan price={totals.buyerRowsTotal} showZeroKop />
        </TableCell>
        <TableCell align="right">
          <PriceSpan price={totals.buyerPartialPaidTotal ?? 0} showZeroKop />
        </TableCell>
        {/* {isClientInvoice &&
          <TableCell align="right">
            <PriceSpan isApproximate price={totals.totalProfitByPayments ?? 0} zeroAsDash showZeroKop coloring />
          </TableCell>
        } */}
        {isClientInvoice &&
          <TableCell align="right">
            <ProfitWithProfitabilty price={totals.buyerRowsTotal} profit={totals.totalProfitByPurchases} />
          </TableCell>
        }
      </TableRow>
      <TableRow >
        <TableCell colSpan={4} align='right'>Итого расходы:</TableCell>
        <TableCell align="right">
          <PriceSpan price={totals.sellerrRowsTotal ?? 0} showZeroKop />
        </TableCell>
        <TableCell align="right">
          <PriceSpan price={totals.sellerPartialPaidTotal ?? 0} showZeroKop />
        </TableCell>
        {isClientInvoice && <TableCell />}
        {isClientInvoice && <TableCell />}
      </TableRow>
    </>
  );
};


const CellLayout = ({ top, bottom }: { top: ReactNode, bottom: ReactNode }) => (
  <>
    <div>{top}</div>
    <div>{bottom}</div>
  </>
);

const ProfitWithProfitabilty = ({ price, profit }: { price?: number, profit: number }) =>
  <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
    <div>
      <PriceSpan price={profit} zeroAsDash showZeroKop coloring />
    </div>
    <div>
      {!!price && !!profit &&
        <ProfitabilityBadge valueInPercent={(100 * (profit / price))} />
      }
    </div>
  </Stack>;



