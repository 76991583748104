import { FormValues, initialValues, toCreateCompanyDto } from './company-dialog.form';
import { CompanyDialogCore, DialogProps } from './company-dialog';
import { CreateCompanyDto, IContacts } from '@sr/dto';

interface AddDialogProps extends DialogProps<CreateCompanyDto> {
  contacts?: IContacts;
}

export const AddCompanyDialog = (props: AddDialogProps) => {
  const { open, onClose, onSubmit, contacts } = props;

  const handleSubmit = async (values: FormValues) => {
    const formValues = values;

    if (contacts) {
      formValues.companyContacts = contacts;
    }
    if (!values.cityId) {
      formValues.cityId = undefined;
    }
    const newCompanyData = toCreateCompanyDto(formValues);
    await onSubmit(newCompanyData);
    onClose();
  };

  return (
    <CompanyDialogCore isCreateMode={!contacts} open={open} onClose={onClose} title="Создание новой фирмы" formInitialValues={() => initialValues} handleSubmit={handleSubmit} />
  );
};
