import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { salaryEntriesApi } from './salary-entries.api';
import { SalaryEntryDto } from '@sr/dto';

export const useSalaryData = (year: number, month: number, userId: number) => {
  const { data, isLoading, error } = salaryEntriesApi.useGetSalaryEntriesQuery(
    { year, month, userId },
    { refetchOnMountOrArgChange: 120 }
  );

  return {
    rows: data ? data : [] as SalaryEntryDto[],
    error: extractNestJsErrorMessage(error),
    isLoading,
  };
};
export const useSummary = (year: number, month: number) => {
  const { data, isLoading, error } = salaryEntriesApi.useGetSummaryQuery(
    { year, month },
    { refetchOnMountOrArgChange: 120 }
  );

  return {
    rows: data ? data : [],
    error: extractNestJsErrorMessage(error),
    isLoading,
  };
};

export const useDeleteSalaryEntry = (year: number, month: number, userId: number) => {
  const [callDelete] =  salaryEntriesApi.useDeleteSalaryEntryMutation();
  return (entryId: number)=>{
    return callDelete({ year, month, userId, entryId }).unwrap();
  };
};
