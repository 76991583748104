import { TableCell, TableRow } from '@mui/material';
import PriceSpan from '../../../shared/ui/price-span';

export const TotalsRow = ({ totals, rowColor }: { totals: { [k: string]: number }; rowColor?: string }) => {
  return (
    <TableRow sx={{ backgroundColor: rowColor || undefined }}>
      <TableCell sx={{ borderTop: '3px black' }} align="right">
        Итого:
      </TableCell>
      {Object.values(totals).map((item, index) => (
        <TableCell key={`totals ${index}`}>
          <PriceSpan price={item} />
        </TableCell>
      ))}
    </TableRow>
  );
};
