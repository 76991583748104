import { Type } from "class-transformer";
import { IsIn, IsNumber, IsOptional, IsString } from "class-validator";
import { ContractAppendixDto } from "./contract-appendix.dto";
import { ContractTemplateInfoDto } from "./contract-template.dto";
import { DownloadFileDto } from "./download-file.dto";
import { ContractStatus, CONTRACT_STATUSES_KEYS, ContractType, CONTRACT_TYPE_CLIENT, CONTRACT_TYPE_SUPPLIER } from "./enums/contract-statuses";
import { LegalEntityDto, LegalEntityBaseInfoDto } from "./legal-entity.dto";
import { SignerDto } from "./signer.dto";
import { UserDto } from "./user.dto";

class ContractBase {
  @IsString()
  name: string;

  @IsIn(CONTRACT_STATUSES_KEYS)
  status: ContractStatus;

  @Type(() => Date)
  contractStartDate: Date;

  @Type(() => Date)
  contractEndDate?: Date;

  @IsNumber()
  @IsIn([CONTRACT_TYPE_CLIENT, CONTRACT_TYPE_SUPPLIER])
  type: ContractType;

  @IsOptional()
  @IsString()
  specialConditions?: string;

  contractTemplateId: number;

  autoprolong: boolean;
}

export class CreateContractDto extends ContractBase {
  managerId: number;

  sellerLegalEntityId: number;
  sellerBankDetailsId: number;
  sellerSignerId: number;

  buyerLegalEntityId: number;
  buyerBankDetailsId: number;
  buyerSignerId: number;

  scanFile: File;
}

export class ContractDto extends ContractBase {
  id: number;
  @Type(() => ContractTemplateInfoDto)
  template: ContractTemplateInfoDto;

  @Type(() => LegalEntityDto)
  seller: LegalEntityDto;

  @Type(() => LegalEntityDto)
  buyer: LegalEntityDto;

  @Type(() => SignerDto)
  sellerSigner: SignerDto;

  @Type(() => SignerDto)
  buyerSigner: SignerDto;

  @Type(() => UserDto)
  manager: UserDto

  @Type(() => ContractAppendixDto)
  appendicies: ContractAppendixDto[];

  @Type(() => DownloadFileDto)
  scanFile: DownloadFileDto | null;

  isActive: boolean;
}

export class ContractShortInfoDto {
  id: number;

  @Type(() => Date)
  contractStartDate: Date;

  @Type(() => Date)
  contractEndDate?: Date;
}

export class ContractInfoDto extends ContractBase {
  id: number;

  @Type(() => LegalEntityBaseInfoDto)
  seller: LegalEntityBaseInfoDto;

  @Type(() => LegalEntityBaseInfoDto)
  buyer: LegalEntityBaseInfoDto;

  @Type(() => UserDto)
  manager: UserDto

  isActive: boolean;
}

export class UpdateContractDto extends ContractBase {
  id: number;

  scanFile?: File | null;

  @IsNumber()
  managerId: number;
}

