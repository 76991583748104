type NOT_PROCESSED = 1;
type IN_PROGRESS = 2;
type WAITING_FOR_DECISION = 3;
type CLOSED = 4;
type DECLINED = 5;
type SELL = 6;
type PRICE = 7;
type PRICE_PROCESSED = 8;
type SPAM = 9;
type UNKNOWN = 10;
type UNKNOWN_PROCESSED = 11;

export const LEAD_STATUS_NOT_PROCESSED: NOT_PROCESSED = 1;
export const LEAD_STATUS_IN_PROGRESS: IN_PROGRESS = 2; //
export const LEAD_STATUS_WAITING_FOR_DECISION: WAITING_FOR_DECISION = 3;
export const LEAD_STATUS_CLOSED: CLOSED = 4;
export const LEAD_STATUS_DECLINED: DECLINED = 5; //
export const LEAD_STATUS_SELL: SELL = 6; //
export const LEAD_STATUS_PRICE: PRICE = 7;
export const LEAD_STATUS_PRICE_PROCESSED: PRICE_PROCESSED = 8;
export const LEAD_STATUS_SPAM: SPAM = 9;
export const LEAD_STATUS_UNKNOWN: UNKNOWN = 10;
export const LEAD_STATUS_UNKNOWN_PROCESSED: UNKNOWN_PROCESSED = 11;

export type LeadStatus = NOT_PROCESSED | IN_PROGRESS | WAITING_FOR_DECISION | CLOSED | DECLINED | PRICE | SELL | PRICE_PROCESSED | SPAM | UNKNOWN | UNKNOWN_PROCESSED;

export const LEADS_STATUSES: { [key in LeadStatus]: string } = {
  [LEAD_STATUS_NOT_PROCESSED]: 'Не обработан',
  [LEAD_STATUS_IN_PROGRESS]: 'В работе',
  [LEAD_STATUS_WAITING_FOR_DECISION]: 'Ждем решения',
  [LEAD_STATUS_CLOSED]: 'Закрыт',
  [LEAD_STATUS_DECLINED]: 'Отказной лид',
  [LEAD_STATUS_SELL]: 'Продажа',
  [LEAD_STATUS_PRICE]: 'Прайс',
  [LEAD_STATUS_PRICE_PROCESSED]: 'Прайс обработан',
  [LEAD_STATUS_SPAM]: 'Спам',
  [LEAD_STATUS_UNKNOWN]: 'Неизвестный',
  [LEAD_STATUS_UNKNOWN_PROCESSED]: 'Неизвестный обработан',
};
