import { Box, Button, Card, CardActions, CardContent, MenuItem, Stack } from '@mui/material';
import { WccDto, WCC_STATUSES_MAP, WccStatus } from '@sr/dto';
import { useSnack } from 'shared/ui/snack';
import { FormValues, formTools } from './wcc-basic-supplier.form-tools';
import { Field, Formik, FormikProps } from 'formik';
import { DatePickerField } from 'shared/ui/formik-fields/date-picker';
import { TextField, Select } from 'formik-mui';
import { useSignerDialog } from 'shared/providers/legal-entities.provider';
import { SignerPicker } from 'shared/ui/formik-fields/signer-picker';
import FilePickerField from 'shared/ui/formik-fields/file-picker';
import { useInvoiceSupplierWcc } from 'features/invoice/invoice-wcc/invoice-wcc.hook';
import { format } from 'date-fns';

export const WccBasicsForm = ({ wcc, legalEntityId }: { wcc: WccDto, legalEntityId: number; }) => {

  const { showSuccess, showError } = useSnack();

  const sellerSignerDialog = useSignerDialog(legalEntityId);
  const { updateInvoiceSupplierWcc, reload: wccReload } = useInvoiceSupplierWcc(wcc.id);

  const handleSubmit = async (formData: FormValues) => {
    return updateInvoiceSupplierWcc(
      {
        ...formData,
        id: wcc.id,
        date: format(new Date(formData.date), 'yyyy-MM-dd'),
      }
    )
      .then(() => {
        showSuccess('Данные акта уѝпешно обновлены')
        wccReload()
      })
      .catch(e => {
        showError(`Ошибка обновлениѝ данных акта: ${e.data.message}`)
      });
  };

  return (
    <Card>
      <Formik
        initialValues={formTools.fromDto(wcc)}
        enableReinitialize
        onSubmit={handleSubmit}
        validate={formTools.validate}
      >
        {({ submitForm, isSubmitting, dirty }: FormikProps<FormValues>) => (
          <>
            <CardContent>
              <Stack direction="column" spacing={2}>
                <Field
                  name="wccNumber"
                  component={TextField}
                  label="Номер Нкта"
                />

                <Field
                  name="date"
                  label="Дата акта"
                  component={DatePickerField}
                />

                <Field
                  name="amount"
                  component={TextField}
                  label="Сумма акта"
                />

                <Field
                  name="status"
                  label="Статуѝ"
                  component={Select}
                >
                  {Object.values(WccStatus).map(key =>
                    <MenuItem key={key} value={key}>
                      {WCC_STATUSES_MAP[key]}
                    </MenuItem>
                  )}
                </Field>

                <Field
                  label='Главный бухгалтер'
                  component={SignerPicker}
                  legalEntityId={legalEntityId}
                  collection={sellerSignerDialog.collection}
                  name='sellerSignerId' />
                <Box>
                  Файл Нкта
                  <Field
                    name='aktFile'
                    component={FilePickerField}
                  />
                </Box>
                <Box>
                  Файл УПД
                  <Field
                    name='updFile'
                    component={FilePickerField}
                  />
                </Box>
                <Box>
                  Доп. файлы
                  <Field
                    name='otherFiles'
                    component={FilePickerField}
                    multiple
                  />
                </Box>
              </Stack>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                sx={{ ml: 'auto', mr: 1 }}
                onClick={submitForm}
                disabled={!dirty || isSubmitting}
              >
                Сохранить
              </Button>
            </CardActions>
          </>
        )}
      </Formik>
    </Card >
  );
};
