export type ContractAppendixStatus = 1 | 2 | 3 | 4 | 5;

export const CAPPENDIX_STATUS_READY = 1;

export const CAPPENDIX_STATUSES: { [key in ContractAppendixStatus]: string } = {
  1: 'Готов',
  2: 'Отправлен',
  3: 'Загружен скан',
  4: 'Подтвержден скан',
  5: 'Получен оригинал',
};

export const CAPPENDIX_STATUSES_KEYS = Object.keys(CAPPENDIX_STATUSES).map((k) => Number(k) as ContractAppendixStatus);
