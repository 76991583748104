import { MenuItem, Stack } from '@mui/material';
import { Field, FormikErrors }from 'formik';
import { DatePickerField } from 'shared/ui/formik-fields/date-picker';
import { TextField } from 'formik-mui';
import { FormDialog } from 'shared/ui/form-dialog';
import { FormTools, VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';
import { CreateInvoiceWccSupplierDto, UpdateInvoiceResource, WccDto } from '@sr/dto';

const NOW = new Date();

export interface FormData {
    date: Date | '' | string;
    wccNumber: string | '',
    amount: number | '',
}

const initialValues: FormData =  {
    date: NOW,
    wccNumber: '',
    amount: '',
}

type Props = {
  open: boolean;
  onSubmit: (data: FormData) => Promise<void>;
  onClose: () => void;
};

export const formTools: FormTools<WccDto, CreateInvoiceWccSupplierDto, UpdateInvoiceResource, FormData> = {

  toUpdateDto: function (id: number, formData: FormData) {
    throw new Error('Function not implemented.');
  },
  toCreateDto: function (formData: FormData) {
    throw new Error('Function not implemented.');
  },
  fromDto: function (dto: WccDto) {
    throw new Error('Function not implemented.');
  },

  validate: (values: FormData): FormikErrors<FormData> => {
    let validationResult: FormikErrors<FormData> = {};
  
    if (!values.wccNumber)
      validationResult.wccNumber = VALIDATION_REQUIRED_FIELD;

    if (!values.amount)
      validationResult.amount = VALIDATION_REQUIRED_FIELD;

    if (!values.date)
      validationResult.date = VALIDATION_REQUIRED_FIELD;

    return validationResult;
  },
  emptyValues: () => ({
    date: NOW,
    wccNumber: '',
    amount: '',
  })
};

export function WccSupplierInvoiceDialog(props: Props){
  const {
    open,
    onSubmit,
    onClose,
  } = props;

  return (
    <FormDialog    
      renderForm={() => <FormFields/>}
      renderTitle="Внести закрывающий документ"
      open={open}
      onClose={onClose}
      onSubmit={ onSubmit }
      validate={formTools.validate}
      validateOnChange={true}
      initialValues={initialValues}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    />
  );
}

const FormFields = () => {
    return (
    <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
      <Field
        name='wccNumber'
        component={TextField}
        label='Номер'
        required
      />
      <Stack direction="row" spacing={2}>
        <Field
          name='date'
          label='Дата'
          component={DatePickerField}
          required
        />
      </Stack>
      <Field
        name='amount'
        label='Сумма'
        component={TextField}
        required
      />
    </Stack>);
};