import { SalaryEntryDto, SalarySummaryDto } from '@sr/dto';
import { salaryApi } from 'entities/salary/salary.api';

export type SalaryEntryQuery = {
  year: number;
  month: number;
  userId: number;
};

export type SalarySummaryQuery = Partial<SalaryEntryQuery>;

const injectedApi = salaryApi.injectEndpoints({
  endpoints: (builder) => ({
    getSalaryEntries: builder.query<SalaryEntryDto[], SalaryEntryQuery>({
      query: ({ year, month, userId }: SalaryEntryQuery) => ({
        url: `entries/${year}/${month}/${userId}`,
      }),
    }),
    getSummary: builder.query<SalarySummaryDto[], SalarySummaryQuery>({
      query: ({ year, month }: SalarySummaryQuery) => ({
        url: `summary/${year}/${month}`,
      }),
    }),

    deleteSalaryEntry: builder.mutation<void, SalaryEntryQuery & { entryId: number }>({
      query: ({ entryId }) => ({
        url: `entries/${entryId}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ year, month, userId, entryId }, { dispatch, queryFulfilled, getCacheEntry, getState }) {
        const patchResult = dispatch(
          injectedApi.util.updateQueryData('getSalaryEntries', { year, month, userId }, (draft) => {
            const index = draft.findIndex((entry) => entry.id === entryId);
            if (index !== -1) {
              draft.splice(index, 1);
            }
          }),
        );
        try {
          await queryFulfilled;
        } catch (e) {
          patchResult.undo();
          console.error('RTK Query onQueryStarted error', e);
        }
      },
    }),
  }),
});

export const salaryEntriesApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['salary-entries', 'summary'],
  endpoints: {
    getSalaryEntries: {
      providesTags: (result, error, query) => (result ? [{ type: 'salary-entries', id: `${query.year}-${query.month}-${query.userId}` }] : ['salary-entries']),
    },
    getSummary: {
      providesTags: (result, error, query) => (result ? [{ type: 'summary', id: `${query.year}-${query.month}-${query.userId}` }] : ['summary']),
    },
  },
});
