import { Stack, Button, Card, CardActions, CardContent, MenuItem } from '@mui/material';
import { FormData, formTools } from './basic-invoice.form-tools';
import { Field, Formik, FormikProps } from 'formik';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { useSnack } from 'shared/ui/snack';
import { DatePickerField } from 'shared/ui/formik-fields/date-picker';
import { UserPickerField } from 'features/user/user-picker/ui';
import { useInvoiceMutableData } from 'entities/invoice/mutable-data/lib';
import { useActionCreators } from 'shared/store';
import { SwitchField } from 'shared/ui/formik-fields/switch-field';
import { TextField } from 'formik-mui';
import { INVOICE_PRINT_FORM_DATE_KEYS, INVOICE_PRINT_FORM_DATE_VIEW_STATUSES } from '@sr/dto';
import { invoiceBasicsThunks } from 'entities/invoice/mutable-data/thunks';
import { InvoiceDto } from '@sr/dto';

type Props = {
  allowEditEndDate: boolean;
  invoice: InvoiceDto;
};

export const InvoiceBasicForm = ({ allowEditEndDate, invoice }: Props) => {
  const data = useInvoiceMutableData();
  const { updateInvoiceBasicsThunk } = useActionCreators(invoiceBasicsThunks);

  const { showSuccess, showError } = useSnack();

  if (!data)
    return <LoadingBanner />;


  const handleSubmit = async (formData: FormData) => {
    return updateInvoiceBasicsThunk(formTools.toUpdateDto(invoice.id, formData))
      .unwrap()
      .then(() => showSuccess('Данные счета успешно обновлены'))
      .catch(e => showError('Ошибка обновления данных счета', e));
  };

  return (
    <Card>
      <Formik
        initialValues={formTools.fromDto(data)}
        validate={formTools.validate}
        onSubmit={handleSubmit}
      >
        {({ submitForm, dirty, isSubmitting, isValid, values }: FormikProps<FormData>) => (
          <>
            <CardContent>
              <Stack direction="column" spacing={2} >
                <Stack direction="column" spacing={2}>
                  <Field
                    name="startDate"
                    label='Дата выставления'
                    component={DatePickerField}
                  />
                  { values.generateWcc && <Field
                    name="endDate"
                    label='Дата завершения'
                    disabled={!allowEditEndDate}
                    component={DatePickerField}
                  />
                  }
                  <Field
                    name="managerId"
                    label="Менеджер"
                    component={UserPickerField}
                  />
                  <Field
                    name="printFormShowMediaLink"
                    label="Выводить ссылку на СМИ в печатных формах"
                    type='checkbox'
                    component={SwitchField}
                  />
                  <Field
                    name="generateWcc"
                    label="Формировать акты"
                    type='checkbox'
                    component={SwitchField}
                  />
                  <Field
                    name="printFormShowDateViewState"
                    label="Даты оказания услуг"
                    select
                    component={TextField}
                  >
                    {INVOICE_PRINT_FORM_DATE_KEYS.map(key=> (<MenuItem key={key} value={key} >
                      {INVOICE_PRINT_FORM_DATE_VIEW_STATUSES[key]}
                    </MenuItem>)
                    )
                    }
                  </Field>

                </Stack>
              </Stack>

            </CardContent>
            <CardActions>
              <Button
                sx={{ ml: 'auto', mr: 1 }}
                variant="contained"
                onClick={submitForm}
                disabled={!dirty || isSubmitting || !isValid}
              >
                Сохранить
              </Button>
            </CardActions>
          </>
        )}

      </Formik>
    </Card >
  );
};
