import { invoiceDataApi } from 'entities/invoice/details/api';


export const useSetLegalEntityTax = (invoiceId: number) => {
  const [update, { isLoading }] = invoiceDataApi.useSetInvoiceLegalEntityTaxMutation();
  return {
    setLegalEntityTax: (legalEntityTax: number) => update({ invoiceId, legalEntityTax }).unwrap(),
    isLoading
  };
};
