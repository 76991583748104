import { useSearchParams } from 'react-router-dom';
import { Field, FormikProvider, useField, useFormik } from 'formik';
import {
  formValuesFromUrlSearchParams,
  mergeFormValuesVsCurrentSearchParams, SerializableValues,
} from 'utils/url-search-params.helper';
import { ReactElement, useEffect } from 'react';
import { PAGE_FIELD_NAME } from 'shared/hooks/paging-params';

export type BaseUrlParamsFilterProps = {
  queryParamName: string,
  size?: 'small' | 'medium',
  label?: string,
  withNonSelect?: boolean,
}

type Props<ValueType> = BaseUrlParamsFilterProps & {
  children: ReactElement<typeof Field>;
  defaultValue?: ValueType | null;
}

/**
 * Когда используете данный фильтр он просто обновляет URLSearchParams а адресной строке браузера.
 * Компонент списка должен подписаться на URLSearchParams и обновлять состояние соответственно
 */
export const BaseUrlParamsFilter = <ValueType extends SerializableValues, >({ queryParamName, defaultValue = null as ValueType | null, children }: Props<ValueType>) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultValues: Record<string, ValueType | null> = {
    [queryParamName]: defaultValue,
  };

  const formik = useFormik<Record<string, ValueType | null>>({
    initialValues: formValuesFromUrlSearchParams(searchParams, defaultValues),
    onSubmit: () => {},
    validateOnChange: true,
  });

  const formValue: ValueType | null = formik.values[queryParamName] ?? null;

  useEffect(() => {
    if (!formik.isValid) return ;
    setSearchParams(prev => {
      const params = mergeFormValuesVsCurrentSearchParams(prev, formik.values, defaultValues);
      if (Object.keys(formik.touched).length || formik.dirty) {
        // раз пользователь обновил фильтр, сбрасываем страницу на 0
        params.delete(PAGE_FIELD_NAME);
      }
      return params;
    });
  }, [formValue]);

  return (
    <FormikProvider value={formik}>
      {children}
    </FormikProvider>
  );
};
