import { INVOICE_PRINT_NOT_SHOW_DATE, InvoiceBasicResource, UpdateInvoiceResource } from '@sr/dto';
import { FormTools, VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';
import { FormikErrors } from 'formik';
import { isBefore } from 'date-fns';

export type FormData = {
  startDate: Date | '',
  endDate: Date | '',
  managerId: number | null;
  printFormShowMediaLink: boolean;
  printFormShowDateViewState: number;
  generateWcc?: boolean;
  legalEntityTax?: number;
  invoiceSupplier?: boolean;
};

export const formTools: FormTools<InvoiceBasicResource, void, UpdateInvoiceResource, FormData> = {
  toUpdateDto: (id: number, formData: FormData) => ({
    id: id,
    startDate: new Date(formData.startDate),
    endDate: formData.generateWcc ? new Date(formData.endDate) : undefined,
    managerId: Number(formData.managerId),
    printFormShowMediaLink: formData.printFormShowMediaLink,
    printFormShowDateViewState: formData.printFormShowDateViewState,
    generateWcc: formData.generateWcc,
    legalEntityTax: Number(formData.legalEntityTax),
    invoiceSupplier: formData.invoiceSupplier
  }),
  toCreateDto: (formData: FormData) => { },
  fromDto: function (dto: InvoiceBasicResource): FormData {
    return {
      managerId: dto.managerId,
      startDate: new Date(dto.startDate),
      endDate: dto.endDate ? new Date(dto.endDate) : '',
      printFormShowMediaLink: dto.printFormShowMediaLink,
      printFormShowDateViewState: dto.printFormShowDateViewState,
      generateWcc: dto.generateWcc,
      legalEntityTax: dto.legalEntityTax,
      invoiceSupplier: dto.invoiceSupplier
    };
  },
  validate: (values: FormData): FormikErrors<FormData> => {
    let validationResult: FormikErrors<FormData> = {};

    if (values.startDate === '') validationResult.startDate = VALIDATION_REQUIRED_FIELD;

    if (values.startDate !== '' && (!values.invoiceSupplier && values.endDate !== '') &&
      isBefore(values.endDate, values.startDate)) {
      validationResult.startDate = validationResult.endDate = 'Дата выставления не может быть позже даты завершения';
    }

    if (!values.managerId)
      validationResult.managerId = VALIDATION_REQUIRED_FIELD;

    const tax = Number(values.legalEntityTax);
    if(isNaN(tax) || tax < 0 || tax > 100)
      validationResult.legalEntityTax = 'Значение налога должно быть числом от 0 до 100';

    return validationResult;
  },
  emptyValues: () => {
    const now = new Date();
    const nextMonth = new Date();

    nextMonth.setMonth(now.getMonth() + 1);
    return {
      startDate: now,
      endDate: nextMonth,
      managerId: null,
      printFormShowMediaLink: false,
      printFormShowDateViewState: INVOICE_PRINT_NOT_SHOW_DATE,
      generateWcc: true,
      legalEntityTax: undefined,
      invoiveSupplier: false
    };
  }
};
