import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Navigate } from 'react-router-dom';
import { loginThunk } from 'features/auth/auth-slice';
import { useTitleHook } from 'hooks/page-title.hook';

function LoginPage() {
  useTitleHook('Войти');
  const dispatch = useDispatch();
  const { pendingRequest, requestFailed, error, user } = useSelector(store => store.auth);
  //const user = useSelector(store => store.auth.user);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const loginData = {
      login: formData.get('login'),
      password: formData.get('password'),
    };
    dispatch(loginThunk(loginData));
  };

  if (user) {
    return (<Navigate to='/' />);
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >

        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Solution 2.0
        </Typography>


        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          {requestFailed && (<Alert severity="error">
            <AlertTitle>Ошибка</AlertTitle>
            {error}
          </Alert>)}
          <TextField
            margin="normal"
            required
            fullWidth
            id="login"
            label="Логин"
            name="login"
            autoComplete="login"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Пароль"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Вход
          </Button>
          {pendingRequest && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }} />)}
        </Box>
      </Box>
    </Container>
  );
}

export default LoginPage;
