import { SyntheticEvent, useEffect, useState } from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';
import { TextFieldProps, fieldToTextField } from 'formik-mui';

type CollectionItem = {
  id: number;
  label: string;
}

type CollectionMultiSelectProps = {
  items: CollectionItem[];
  isLoading: boolean;
  clearable?: boolean;
  size?: 'small' | 'medium' | undefined;
} & TextFieldProps;

export const CollectionMultiSelect = (props: CollectionMultiSelectProps) => {
  const {
    form: { setFieldValue, initialValues, resetForm },
    field: { name },
    size = undefined,
  } = props;

  const {
    items,
    isLoading,
    ...textFieldProps
  } = props;

  const initialIds: number[] = initialValues[name] ?? [];
  const [selectedIds, setSelectedIds] = useState<number[]>(initialIds.map(Number));
  const onChange = (
    _event: SyntheticEvent<Element, Event>,
    newValue: { id: number }[] | null
  ) => {
    const newSelectedIds = newValue ? newValue.map(item => Number(item.id)) : [];
    setSelectedIds(newSelectedIds);
  };

  const [open, setOpen] = useState(false);
  const loading = open && items.length === 0;

  useEffect(() => {
    if(selectedIds.length === 0){
      resetForm({ values: {} });
    } else {
      setFieldValue(name, selectedIds);
    }
  }, [initialIds.length, selectedIds, name]);

  const selectedValues = items.filter(item => selectedIds.includes(item.id));

  return (
    <Autocomplete
      fullWidth
      multiple
      value={selectedValues}
      options={items}
      disabled={props.disabled}
      open={open}
      size={size}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.label}
      loading={loading}
      loadingText={'Загрузка...'}
      noOptionsText={'Ничего не найдено'}
      renderTags={(value, getTagProps) => {
        const displayedValues = value.slice(0, 4);
        const additionalCount = value.length > 4 ? value.length - 4 : 0;

        return displayedValues.map((chip, index) => (
          <Chip
            label={chip.label}
            {...getTagProps({ index })}
            key={chip.id}
          />
        )).concat(
          additionalCount > 0 ? <Chip key="more" label={`+ ${additionalCount}`} /> : <></>);
      }}
      renderInput={(params) => (
        <TextField
          {...fieldToTextField(textFieldProps)}
          name={name}
          {...params}
          disabled={props.disabled}
          label={textFieldProps.label}
          placeholder="Выбрать..."
        />
      )}
    />
  );
};
