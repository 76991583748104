import { useMemo } from 'react';
import { AuthorizedUser } from '@sr/dto';
import { getCurrentAndPreviousMonthYear }  from '../../utils/get-year-and-month';
import { Box, Typography, Stack } from '@mui/material';
import { UserTimeSheetGrid, useTimeSheetDataByUser } from '../../features/salary/time-sheet/time-sheet.hook';
import { CircularProgressWithText } from '../../components/Mui/circular-progress-with-text';

export const Worklog = ({ user }:{user: AuthorizedUser} ) => {

  const { currentMonth, currentYear, previousMonth, previousYear } = getCurrentAndPreviousMonthYear();
  const currentTimeSheet = useTimeSheetDataByUser(currentYear,currentMonth,user.id);
  const previousTimeSheet = useTimeSheetDataByUser(previousYear,previousMonth,user.id);

  const getPeriods = (timeSheet: UserTimeSheetGrid, type: 'total' | 'logs') => {
    const periods: Record<number, number>  =  timeSheet.rows.length === 2?
      JSON.parse(timeSheet.rows[type === 'total' ? 0 : 1].payload): [];
    return Object.values(periods)
      .reduce((acc, cur: number) => acc+Number(cur), 0);
  };

  const worklogData = useMemo(()=> {
    return [
      {
        key: 'current',
        month: currentMonth,
        year: currentYear,
        logs: getPeriods(currentTimeSheet, 'logs'),
        total: getPeriods(currentTimeSheet, 'total')
      },
      { key: 'previous',
        month: previousMonth,
        year: previousYear,
        logs: getPeriods(previousTimeSheet, 'logs'),
        total: getPeriods(previousTimeSheet, 'total')
      }];
  },[currentMonth, currentTimeSheet, currentYear, previousMonth, previousTimeSheet, previousYear]);

  return (
    <Box sx = {{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around' }}>
      {worklogData.map((item)=> {
        const worklogPercentage = Number(((item.logs * 100) / item.total || 0).toFixed(2));
        return (
          <Box key = {item.key} sx = {{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant = "subtitle2" marginY = {3}>Период <b>{item.month + 1}/{item.year}</b></Typography>
            <Stack direction={'row'} gap = {8} alignItems={'center'}>
              <CircularProgressWithText
                value={worklogPercentage} size={270}
                text = {
                  <Box sx = {{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                      <Typography variant="h2" color = "primary">{item.logs}ч/</Typography>
                      <Typography variant="h4">{item.total}ч</Typography>
                    </Box>
                    <Typography variant="h5">{worklogPercentage}%</Typography>
                  </Box>
                } />
              <Box>
                <Typography>Всего рабочих часов: <b>{item.total}ч</b> </Typography>
                <Typography>Отработано: <b>{item.logs}ч</b></Typography>
                <Typography>Процент отработки: <b>{worklogPercentage}%</b> </Typography>
              </Box>
            </Stack>
          </Box>
        );
      })}
    </Box>
  );
};


