import { Field, FormikProvider, useFormik, } from 'formik';
import { Box, Button, Stack, } from '@mui/material';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import { LegalEntityOurDataDto } from '@sr/dto';
import { formTools, LegalEntityOurDataFormikFormData } from './our-legal-entity.form-tools';
import { useAddOurEntityData, useUpdateOurEntityData } from './our-legal-entities.hook';
import { useSnack } from 'shared/ui/snack';
import FilePickerField from 'shared/ui/formik-fields/file-picker';
import { SignerPicker } from 'shared/ui/formik-fields/signer-picker';
import { useSignerDialog } from 'shared/providers/legal-entities.provider';

export type LegalEntityOurDataFormProps = {
  legalEntityId: number;
  serverData: LegalEntityOurDataDto | undefined
}

export const OurLegalEntityForm = ({ legalEntityId, serverData }: LegalEntityOurDataFormProps) => {
  let initialData = formTools.emptyValues();
  if (serverData) {
    initialData = formTools.fromDto(serverData);
  }

  const sellerSignerDialog = useSignerDialog(legalEntityId);

  const { showSuccessAdd, showFailedAdd, showSuccessUpdate, showFailedUpdate } = useSnack({
    itemTitleForm1: 'Данные нашего юрлица', itemTitleForm2: 'Данных нашего юрлица', gender: 'plural'
  });

  const addOurEntityData = useAddOurEntityData(legalEntityId);
  const updateOurEntityData = useUpdateOurEntityData(legalEntityId);

  const formik = useFormik<LegalEntityOurDataFormikFormData>({
    initialValues: initialData,
    onSubmit: async (formikData) => {
      if (!serverData) {
        const dto = formTools.toCreateDto(formikData);
        return addOurEntityData(dto).then(()=>{ showSuccessAdd() }).catch((e)=>{ showFailedAdd(e) });
      }
      const dto = formTools.toUpdateDto(serverData.id, formikData);
      return updateOurEntityData(dto).then(()=>{ showSuccessUpdate() }).catch((e)=>{ showFailedUpdate(e) });
    },
    validateOnChange: true,
    validate: formTools.validate
  });

  const { isSubmitting, isValid, dirty } = formik;

  return <FormikProvider value={formik}>
    <Box sx={{ p: 1 }}>
      <Field
        fullWidth
        name='invoicePrefix'
        label='Префикс исходящего счета'
        component={TextField}/>
    </Box>
    <Box sx={{ p: 1 }}>
      <Field
        component={TextField}
        label="Ставка налога по юрлицу"
        name="legalEntityTax" />
    </Box>
    <Stack sx={{ mx: 1 }} direction="row" justifyContent="space-between">
      <Box sx={{ width: 1 / 2 }}>
        <Field
          name='isClientInvoicesEnabled'
          Label={{ label:'Может выставлять счета' }}
          type="checkbox"
          component={CheckboxWithLabel}
        />
      </Box>
      <Box sx={{ width: 1 / 2 }}>
        <Field
          name='isSupplierInvoicesEnabled'
          Label={{ label: 'Может оплачивать счета' }}
          type="checkbox"
          component={CheckboxWithLabel}
        />
      </Box>
    </Stack>
    <Stack sx={{p:1,my:2}}>
      <Field
        label='Главный бухгалтер'
        component={SignerPicker}
        legalEntityId={legalEntityId}
        collection = {sellerSignerDialog.collection}
        name='sellerSignerId' />
    </Stack>
    <Stack direction="row" sx={{  justifyContent: 'space-between', p: 1, mt: 3 }}>
      <Field
        name="stamp"
        label="Скан печати"
        component={FilePickerField}
      />
      <Button
        variant="contained"
        type="submit"
        onClick={() => formik.handleSubmit()}
        disabled={isSubmitting || !isValid || !dirty }
      >
        сохранить
      </Button>
    </Stack>
  </FormikProvider>;
};
