import { FieldProps } from 'formik';
import { CollectionAutocomplete } from '../collection-autocomplete';
import { useLeadBudgets } from '../../../entities/lead/lead-budget.hook';

export const LeadBudgetPickerField = ({ field, form, meta }: FieldProps) => {
  const budgets = useLeadBudgets();
  return (
    <CollectionAutocomplete
      field={field}
      form={form}
      isLoading={budgets.isLoading}
      items={budgets.items}
      clearable={true}
      label = {'Бюджет'}
      meta = {meta}/>);
};
