import { FieldProps } from 'formik';
import { LeadQualityPicker }      from '../form-fields/lead-quality-picker';
import { CollectionAutocomplete }     from '../collection-autocomplete';
import { useLeadQualityDropdown } from '../../../entities/lead/lead-quality.hook';

export const LeadQualityPickerField = ({ field, form, meta }: FieldProps) => {

  const quality = useLeadQualityDropdown();
  return (
    <CollectionAutocomplete
      field={field}
      form={form}
      isLoading={quality.isLoading}
      items={quality.items}
      clearable={true}
      label = {'Качество'}
      meta = {meta}/>);
};
