import { useCallback } from 'react';
import {
  CreatePayOrderDto,
  IPagingData,
  PayOrderDto,
  UpdatePayOrderDto,
} from '@sr/dto';
import {
  fetchPayOrders,
  postPayOrder,
  putPayOrder,
  deletePayOrder,
  fetchPayOrdersRelatedToInvoice,
  fetchPayOrder,
} from 'shared/api/payorders';
import { useReadOnlyRemoteCollection } from 'utils/remote-collection.hook';
import { useRemoteItem } from 'utils/remote-item.hook';
import { useReadOnlyRemotePagedCollection } from 'utils/remote-paged-collection.hook';

export const usePayOrders = (paging: IPagingData, filterString?: string) => {
  const collection = useReadOnlyRemotePagedCollection<PayOrderDto>({
    fetchHandler: useCallback(
      () => fetchPayOrders(paging, filterString),
      [filterString, paging]
    ),
    sort: useCallback((a: PayOrderDto, b: PayOrderDto) => b.id - a.id, []),
  });

  const addItem = (data: CreatePayOrderDto) => postPayOrder(data);
  const updateItem = (data: UpdatePayOrderDto) => putPayOrder(data);
  const removeItem = (id: number) => deletePayOrder(id);

  return {
    collection,
    addItem,
    updateItem,
    removeItem,
  };
};

export const useInvoiceRelatedPayOrders = (invoiceId: number) => {
  return useReadOnlyRemoteCollection<PayOrderDto>({
    fetchHandler: useCallback(
      () => fetchPayOrdersRelatedToInvoice(invoiceId),
      [invoiceId]
    ),
  });
};
// TODO Переписать с использованием RTK query
export const usePayOrder = (id: number) => {
  const { updateItem, item, loading, error } = useRemoteItem<
    PayOrderDto,
    UpdatePayOrderDto
  >({
    fetchHandler: useCallback(async () => {
      return await fetchPayOrder(id);
    }, [id]),
    updateHandler: async (data) => {
      return await putPayOrder(data);
    },
  });

  const deleteItem = () => deletePayOrder(id);

  return {
    item,
    updateItem,
    deleteItem,
    loading,
    error,
  };
};
