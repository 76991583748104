import { SyntheticEvent } from 'react';
import { IManager, LeadDto }             from '@sr/dto';
import { NotificationsConfig, useSnack } from 'shared/ui/snack';
import { useUpdateLeadManager } from './lib';
import { Autocomplete, TextField } from '@mui/material';
import { useManagersDropdownList } from '../../user/user-picker';

type Props = {
  lead?: LeadDto
}

const notificationConfig: NotificationsConfig = {
  itemTitleForm1: 'закрепленный менеджер',
  itemTitleForm2: 'закрепленного менеджера',
  gender: 'male'
};
const nonSelectOption = {
  firstName: '-',
  secondName: '',
  id: 0
};

export const LeadManagerChanger = ({ lead }: Props) => {
  const { showSuccessUpdate, showFailedUpdate } = useSnack(notificationConfig);
  const { update, isUpdating } = useUpdateLeadManager();
  const managers = useManagersDropdownList();
  const handleManagerChange = (_event: SyntheticEvent, value: IManager) => {
    if (!lead)
      return;
    const newManager = value;
    if (newManager === null)
      return;

    update({ leadId: lead.id, managerId: newManager.id ? newManager.id : null })
      .unwrap()
      .then(showSuccessUpdate)
      .catch(e => showFailedUpdate(e));
  };

  const val = lead?.manager || nonSelectOption;
  return (
    <Autocomplete
      fullWidth
      value={val}
      options={[nonSelectOption, ...managers.items]}
      onChange={handleManagerChange}
      disableClearable = {true}
      isOptionEqualToValue={(option, value) => (option.id === value.id)}
      getOptionLabel={(option) => `${option.firstName} ${option.secondName}`}
      loadingText={'Загрузка...'}
      loading={isUpdating}
      noOptionsText={'Ничего не найдено'}
      renderInput={
        (params) => <TextField
          {...params}
          label={ 'Менеджер' }
          placeholder="Начните набирать имя..."/>
      }
    />
  );
};
