import { useRef } from 'react';
import { Box, Button, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { useSnack } from 'shared/ui/snack';
import { Heading } from 'shared/ui/list-page-heading';
import { useActionCreators, useStateSelector } from 'shared/store';

import { SalaryEntryForm } from 'entities/salary/salary-entry-dialog/salary-entry.form';
import { SalaryEntriesGrid } from 'entities/salary/salary-entries/salary-entries-grid';
import { salaryDialogActions } from 'entities/salary/salary-entry-dialog/salary-entry.slice';
import { PrepaymentDto } from '@sr/dto';
import { PrepaymentDialogForm } from 'entities/prepayment/prepayment-dialog/prepayment-dialog.form';
import { prepaymentDialogActions } from 'entities/prepayment/prepayment-dialog/prepayment-dialog.slice';
import { fetchPrepayment } from 'entities/prepayment/prepayment.api';
import { useSearchParams } from 'react-router-dom';

export const SalaryEntries = () => {
  const selectedPeriod = useStateSelector((state) => state.salaryPageSelectedPeriod.value);

  const prepaymentToEdit = useRef<PrepaymentDto | null>(null);
  const [searchParams] = useSearchParams();

  const { showError, showSuccess } = useSnack();
  const { openDialog } = useActionCreators(salaryDialogActions);
  const { openDialog: openPrepaymentDialog } = useActionCreators(prepaymentDialogActions);

  const managerValue = searchParams.get('manager');

  const handleAdd = () => {
    prepaymentToEdit.current = null;
    openPrepaymentDialog();
  };

  const handleEdit = (salaryEntryId: number) => {
    fetchPrepayment(salaryEntryId).then((p) => {
      prepaymentToEdit.current = p;
      openPrepaymentDialog();
    });
  };

  return (
    <>
      <Heading
        sx={{ mb: 2 }}
        title="Начисления/Удержания"
        actions={
          <Stack direction="row" spacing={1}>
            <Button variant="contained" startIcon={<AddIcon />} onClick={() => openDialog()}>
              Добавить запись
            </Button>
            <Button variant="contained" startIcon={<AddIcon />} onClick={() => handleAdd()}>
              Внести аванс
            </Button>
          </Stack>
        }
      />
      <SalaryEntryForm />

      <PrepaymentDialogForm prepayment={prepaymentToEdit} selectedPeriod={selectedPeriod} />

      {managerValue && !isNaN(Number(managerValue)) ? (
        <SalaryEntriesGrid year={selectedPeriod.year} month={selectedPeriod.month} userId={Number(managerValue)} onEditClick={handleEdit} />
      ) : (
        <Box sx={{ pt: 2 }}>Выберите менеджера для просмотра заработной платы</Box>
      )}
    </>
  );
};
