import { useParams } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { AgentCard } from 'entities/agent-card/agent-card';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { useTitleHook } from 'hooks/page-title.hook';
import { WccInvoiceRows } from 'entities/wcc/wcc-rows/wcc-rows';
import { InvoiceCard } from 'entities/invoice/invoice-card/invoice-card';
import { PageHeader } from 'shared/ui/details-page-header';
import { WccStatusStepper } from 'entities/wcc/status-stepper/wcc-status-stepper';
import { WccTitle } from 'entities/wcc/wcc-title';
import { WccButton } from 'entities/wcc/wcc-button';
import { WccCard } from 'entities/wcc/wcc-card';
import { useInvoiceWcc } from 'features/invoice/invoice-wcc/lib';
import { WccBasicsForm } from 'features/wcc/wcc-basic-form/wcc-basics-form';

export const WccDetailsPage = () => {
  const { wccId: wccIdStr } = useParams<{ wccId: string; }>();
  const wccId = Number(wccIdStr);
  const { invoiceWcc: wcc, isFetchingWcc } = useInvoiceWcc({ wccId, initialWcc: null });

  useTitleHook(wcc && `Нкт ${wcc?.wccNumber}`);

  if (wcc === null) {
    return <LoadingBanner />;
  }

  return (
    <>
      <PageHeader
        title={<WccTitle wcc={wcc} />}
        actions={<WccButton initialWcc={wcc} invoiceId={wcc.invoice.id} />}
        subHeader={
          <Box maxWidth={800}>
            <WccStatusStepper status={wcc.status} />
          </Box>}
      />

      <Stack direction="row" spacing={2} alignContent="flex-end" justifyItems="center">
        <Box>
          <WccBasicsForm legalEntityId={wcc.invoice.seller.id} wcc={wcc} />
        </Box>
        <Box>
          <Stack direction="column" spacing={2}>
            <WccCard wcc={wcc} />
            <InvoiceCard invoice={wcc.invoice} />
          </Stack>
        </Box>

        <Box>
          <Stack direction="column" spacing={2}>
            <AgentCard title="Покупатель" company={wcc.invoice.buyerCompany} legalEntity={wcc.invoice.buyer} />
            <AgentCard title="Продавец" company={wcc.invoice.sellerCompany} legalEntity={wcc.invoice.seller} />
          </Stack>
        </Box>


      </Stack>
      <Box mt={3}>
        <WccInvoiceRows invoiceId={wcc.invoice.id} />
      </Box>

    </>
  );
};

export default WccDetailsPage;

