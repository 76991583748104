import { plainToInstance, Type } from 'class-transformer';
import {
  IsString,
  IsNumber,
  IsDateString,
  Min,
  IsOptional,
} from 'class-validator';
import { MediaInfoDto } from './media-basic-data.dto';
import { PurchaseDto } from './purchase.dto';

export class CreateInvoiceRowDto {
  @IsNumber()
  invoiceId: number;

  @Type(() => Date)
  startDate: Date;

  @Type(() => Date)
  endDate: Date;

  @IsOptional()
  @IsNumber()
  mediaId?: number;

  @IsString()
  description: string;

  @Min(0)
  @IsNumber()
  amount: number;

  @Type(() => Number)
  @IsNumber({ maxDecimalPlaces: 2 })
  price: number;

  @IsNumber({ maxDecimalPlaces: 2 })
  @Type(() => Number)
  priceDiscount: number;

  @IsOptional()
  vat?: number | null;

  @IsNumber()
  rowOrder: number;
}

export class InvoiceRowDto extends CreateInvoiceRowDto {
  @IsNumber()
  id: number;

  @IsNumber({ maxDecimalPlaces: 2 })
  @Type(() => Number)
  priceExclVat: number;

  @IsOptional()
  @IsNumber()
  vatValue?: number;

  @IsOptional()
  @IsNumber()
  paidAmount?: number;

  @Type(() => MediaInfoDto)
  media?: MediaInfoDto;

  @Type(() => PurchaseDto)
  linkedPurchase?: PurchaseDto;
}

export class UpdateInvoiceRowDto extends CreateInvoiceRowDto {
  @IsNumber()
  id: number;
}
