import { TableRow, TableCell, Stack, IconButton, Typography, Divider, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import { InvoiceRowDto, UpdateInvoiceRowDto } from '@sr/dto';
import { DatePickerField } from 'shared/ui/formik-fields/date-picker';
import { MediaPickerField } from 'entities/media-picker';
import { VatPickerField } from 'shared/ui/formik-fields/vat-picker';
import { LinkToMedia } from 'shared/ui/links/link-to-media';
import PricePair from 'shared/ui/price-pair';
import PriceSpan from 'shared/ui/price-span';
import { Formik, Field } from 'formik';
import { TextField } from 'formik-mui';
import { useState } from 'react';
import { formatDate, formatDatePeriod } from 'utils/date-format-helpers';
import { FormData, toDto, fromDto, validate } from './invoice-row.form';
import { usePlacementCell, PlacementCell } from './placement-cell';

type RowProps = {
  row: InvoiceRowDto;
  isNewRow?: boolean;
  isReadOnly?: boolean;
  isClientInvoice: boolean;
  sellerVat: number | null | undefined;
  onPlaceClick?: (row: InvoiceRowDto) => void;
  onSaveClick: (data: UpdateInvoiceRowDto) => Promise<void>;
  onCancelClick?: (row: InvoiceRowDto) => void;
  onDeleteClick?: (row: InvoiceRowDto) => Promise<void>;
  isCompact?: boolean;
};

const priceTextStyle = { color: '#ed6c02', fontWeight: 700 };

const PriceWithoutVAT = ({ priceExclVat }: { priceExclVat: number }) => {
  return (
    <div>
      <Typography variant="caption" sx={priceTextStyle} component="div">
        Без НДС
      </Typography>
      <PriceSpan size='small' price={priceExclVat} />
      <Divider sx={{ my: 1 }} />
      <Typography variant="caption" sx={priceTextStyle} component="div">
        С НДС
      </Typography>
    </div>
  );
};

export const InvoiceRow = ({ isNewRow, isReadOnly = false, row, sellerVat, isClientInvoice, onPlaceClick, onSaveClick, onDeleteClick, onCancelClick, isCompact = false }: RowProps) => {
  const {
    media,
    description,
    startDate,
    endDate,
    amount,
    price,
    priceExclVat,
    priceDiscount,
    vat
  } = row;

  const [editMode, setEditMode] = useState(false);

  const onSubmitClick = async (values: FormData) => {
    setEditMode(false);
    return onSaveClick(toDto(row, values));
  };

  const toggleEdit = () => {
    setEditMode(!editMode);
  };
  const period = `${formatDate(startDate)} - ${formatDate(endDate)} (${formatDatePeriod(new Date(startDate), new Date(endDate))})`;
  const displayEdit = editMode || isNewRow;

  const { buttonProps, disableDelete } = usePlacementCell(!!isNewRow, row, isClientInvoice);

  return (
    <TableRow
    // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <Formik
        initialValues={fromDto(row)}
        onSubmit={onSubmitClick}
        validate={validate}
        enableReinitialize
      >{
          ({ submitForm, resetForm, dirty, isValid }) => (
            <>
              <TableCell width={600}>
                <Stack direction="column" gap={1}>
                  {isClientInvoice
                    ? !displayEdit
                      ? (media ? <LinkToMedia media={media}>{media.name}</LinkToMedia> : '-')
                      : <Field name='mediaId' component={MediaPickerField} size='small' />
                    : null
                  }
                  {!displayEdit
                    ? description.length > 0 && (
                      <Stack>
                        {isClientInvoice && <Typography variant="caption" fontWeight={600}>Описание</Typography>}
                        <Typography>{description}</Typography>
                      </Stack>)
                    : <Field name='description' component={TextField} size='small' multiline fullWidth label="Описание" />}
                </Stack>
              </TableCell>
              <TableCell width={200} >
                {!displayEdit
                  ? period
                  : <>
                    <Stack direction='column' spacing={1} sx={{
                      '& .MuiInputBase-root': {
                        maxWidth: '138px',
                        '& input': { maxWidth: '80px' },
                        '& button': { padding: 0 }
                      }
                    }}>
                      <Field name='startDate' label='' component={DatePickerField} size='small' />
                      <Field name='endDate' label='' component={DatePickerField} size='small' />
                    </Stack>
                  </>}
              </TableCell>

              <TableCell width={50}>
                {!displayEdit
                  ? `${amount} шт.`
                  : <Field name='amount' label='' component={TextField} size='small' />}
              </TableCell>

              <TableCell width={130}>
                {amount && <PriceSpan size='small' price={(priceExclVat / amount)} />}
              </TableCell>

              <TableCell width={130}>
                {!displayEdit ? (<Box>
                  <PriceWithoutVAT priceExclVat={priceExclVat} />
                  <PricePair size='small' price={price} priceDiscount={priceDiscount} />
                </Box>)
                  : <>
                    <PriceWithoutVAT priceExclVat={priceExclVat} />
                    <Stack direction='column' spacing={1} mt={1}>
                      <Field name='price' label='без скидки' component={TextField} size='small' />
                      <Field name='priceDiscount' label='со скидкой' component={TextField} size='small' />
                    </Stack>
                  </>
                }
              </TableCell>

              <TableCell width={100}>
                {row.vat === null ? 'без НДС' : `${row.vat}%`}
              </TableCell>

              {!isCompact &&
                <PlacementCell {...buttonProps} onPlaceClick={() => onPlaceClick?.(row)} />
              }

              <TableCell align="right" width={60}>
                <Stack sx={{ display: 'flex', flexDirection: 'column' }}>
                  {!displayEdit
                    ?
                    <IconButton edge="end" onClick={toggleEdit} disabled={isReadOnly}>
                      <EditIcon />
                    </IconButton>
                    : <>
                      <IconButton edge="end"
                        onClick={submitForm}
                        disabled={!dirty || !isValid}
                        type='submit'>
                        <CheckIcon />
                      </IconButton>
                      <IconButton edge="end" onClick={() => {
                        resetForm();
                        toggleEdit();
                        onCancelClick?.(row);
                      }}>
                        <CancelIcon />
                      </IconButton>
                    </>
                  }
                </Stack>
                {!isNewRow && <IconButton
                  edge="end"
                  disabled={isReadOnly || disableDelete}
                  onClick={() => onDeleteClick?.(row)}>
                  <DeleteIcon />
                </IconButton>}
              </TableCell>
            </>
          )}
      </Formik>
    </TableRow >);
};
