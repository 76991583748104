import { LeadDto, UpdateLeadPropsDto } from '@sr/dto';
import { leadBaseApi } from 'entities/lead/api';
import { LEAD_BASE_URL } from 'shared/api/api';

const injectedApi = leadBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getLead: builder.query<LeadDto, number>({
      query: (id: number) => {
        return({
          url: `${LEAD_BASE_URL}/${id}/info`,
          method: 'GET',
        });
      },
    }),
    updateLead: builder.mutation<LeadDto, UpdateLeadPropsDto>({
      query: (dto: UpdateLeadPropsDto) => ({
        url: `${LEAD_BASE_URL}/${dto.id}`,
        body: dto,
        method: 'PUT',
      }),
    }),
    // deleteLead: builder.mutation<void, number>({
    //   query: (id: number) => ({
    //     url: `${LEAD_BASE_URL}/${id}`,
    //     method: 'DELETE',
    //   }),
    // }),
  }),
  overrideExisting: true,
});

export const leadApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['lead'],
  endpoints: {
    getLead: {
      providesTags: ['lead'],
    },
    updateLead: {
      invalidatesTags: ['lead'],
    },
  },
});

export const useLeadDetails = (id: number) => {
  const { data, isLoading, error } = leadApi.useGetLeadQuery(id , { refetchOnMountOrArgChange: true });
  return { data, isLoading, error };
};
