import { Box, CircularProgress } from '@mui/material';

type CircularProgressWithTextProps = {
  value: number;
  text: React.ReactNode;
  size: number;
}

export const CircularProgressWithText = ({ value, text, size }: CircularProgressWithTextProps) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" value={value} size={size} sx = {{
        '& .MuiCircularProgress-circle': {
          fill: '#eee'
        }
      }}/>
      <Box
        position="absolute"
        top="50%"
        left="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ transform: 'translate(-50%, -50%)' }}
      >
        {text}
      </Box>
    </Box>
  );
};
