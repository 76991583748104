import {
  Divider,
  Stack,
} from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { BankDetailsPicker } from 'shared/ui/formik-fields/bank-details-picker';
import { DatePickerField } from 'shared/ui/formik-fields/date-picker';
import { LegalEntityPicker } from 'shared/ui/formik-fields/legal-entity-picker';
import { SignerPicker } from 'shared/ui/formik-fields/signer-picker';
import { UserPickerField } from 'features/user/user-picker/ui';
import { ContractTemplatePickerField } from 'shared/ui/formik-fields//contract-template-picker-field';
import { FormValues } from 'components/Contracts/contract.form';
import { EntityRoles } from 'entities/invoice/types';
import { useBankDetailsDialog, useSignerDialog } from 'shared/providers/legal-entities.provider';
import { BankDetailsDialog } from 'components/Companies/BankDetails/bank-details-dialog';
import { SignerDialog } from 'components/Companies/Signers/signer-dialog';
import { ExternalPickerContainer } from 'components/Mui/external-picker-container';
import { CONTRACT_TYPE_CLIENT, CONTRACT_TYPE_SUPPLIER, ContractType } from '@sr/dto';

type Props = {
  ourCompanyId?: number;
  otherCompanyId?: number;
  contractType?: ContractType;
};


export const ContractFormFields = ({ ourCompanyId, otherCompanyId, contractType = CONTRACT_TYPE_CLIENT }: Props) => {
  const { values } = useFormikContext<FormValues>();
  const ourRole = contractType === CONTRACT_TYPE_CLIENT ? 'seller' : 'buyer';
  const clientRole = contractType === CONTRACT_TYPE_CLIENT ? 'buyer' : 'seller';
  // наше юрлицо
  const ourBankDetailsDialog = useBankDetailsDialog(values[`${ourRole}LegalEntityId`] as number);
  const ourSignerDialog = useSignerDialog(values[`${ourRole}LegalEntityId`] as number);
  // юрлицо клиента / поставщика
  const otherBankDetailsDialog = useBankDetailsDialog(values[`${clientRole}LegalEntityId`] as number);
  const otherSignerDialog = useSignerDialog(values[`${clientRole}LegalEntityId`] as number);

  return (
    <Stack direction='column' spacing={2}>
      <Field
        required
        type="hidden"
        name='type'
        value={contractType} />
      <Field
        label='Название'
        fullWidth
        component={TextField}
        name='name' />
      <Stack direction='row' spacing={2}>
        <Field
          required
          label='Дата начала'
          component={DatePickerField}
          name='contractStartDate' />
        <Field
          required
          label='Дата окончания'
          component={DatePickerField}
          name='contractEndDate'
          minDate={values.contractStartDate}
        />
      </Stack>
      {contractType === CONTRACT_TYPE_CLIENT && <Field
        required
        label='Тип договора'
        component={ContractTemplatePickerField}
        name='contractTemplateId' />}
      <Field
        required
        label='Ответственный менеджер'
        component={UserPickerField}
        name='managerId'
        isLinkedToCompany
      />
      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <Stack direction="column" spacing={2} minWidth={400}>
          <Field
            required
            label='Наше юрлицо'
            fullWidth
            component={LegalEntityPicker}
            disableInactive={true}
            entityRole={EntityRoles.INVOICE_ISSUER}
            name={`${ourRole}LegalEntityId`}
            companyIdFilter={ourCompanyId} />
          <Field
            required
            label='Наши банковские реквизиты'
            component={BankDetailsPicker}
            name={`${ourRole}BankDetailsId`}
            legalEntityId={values[`${ourRole}LegalEntityId`]}
            collection={ourBankDetailsDialog.collection} />
          <Field
            required
            label='Наш подписант'
            component={SignerPicker}
            legalEntityId={values[`${ourRole}LegalEntityId`]}
            collection = {ourSignerDialog.collection}
            name={`${ourRole}SignerId`} />
        </Stack>
        <Stack direction="column" spacing={2} minWidth={400} width = {'100%'}>
          <Field
            required
            label={`Юрлицо ${contractType === CONTRACT_TYPE_CLIENT ? 'клиента' : 'поставщика'}`}
            component={LegalEntityPicker}
            disableInactive={true}
            name={`${clientRole}LegalEntityId`}
            companyIdFilter={otherCompanyId} />
          <ExternalPickerContainer disabled = { !values[`${clientRole}LegalEntityId`] }
            onClick={()=> otherBankDetailsDialog.setAddDialogOpen(true)}>
            <Field
              required
              label={`Банковские реквизиты ${contractType === CONTRACT_TYPE_CLIENT ? 'клиента' : 'поставщика'}`}
              component={BankDetailsPicker}
              name={`${clientRole}BankDetailsId`}
              legalEntityId={values[`${clientRole}LegalEntityId`]}
              collection={otherBankDetailsDialog.collection}/>
          </ExternalPickerContainer>
          <ExternalPickerContainer
            disabled = { !values[`${clientRole}LegalEntityId`] }
            onClick = {() => otherSignerDialog.setOpenSignerDialog(true)} >
            <Field
              required
              label={`Подписант ${contractType === CONTRACT_TYPE_CLIENT ? 'клиента' : 'поставщика'}`}
              component={SignerPicker}
              legalEntityId={values[`${clientRole}LegalEntityId`]}
              name={`${clientRole}SignerId`}
              collection = {otherSignerDialog.collection}
            />
          </ExternalPickerContainer>
        </Stack>
      </Stack>
      <Divider />
      <Stack direction='column' sx={{ mt: 2 }}>
        <BankDetailsDialog
          open={otherBankDetailsDialog.addDialogOpen}
          title='Добавить банковские реквизиты'
          onClose={() => otherBankDetailsDialog.setAddDialogOpen(false)}
          submit={otherBankDetailsDialog.handleAddBankDetails} />
        <SignerDialog
          onClose={()=> otherSignerDialog.setOpenSignerDialog(false)}
          open={otherSignerDialog.openSignerDialog}
          submit={otherSignerDialog.handleAddSigner}
          title={'Добавить подписанта'}/>
      </Stack>
      {contractType === CONTRACT_TYPE_SUPPLIER && <Field 
        name='specialConditions'
        label="Особые условия"
        multiline
        rows={4}
        component={TextField}
      />}
    </Stack>
  );
};

