import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { TextFieldProps, fieldToTextField } from 'formik-mui';
import { IManager } from '@sr/dto';
import { useReadOnlyRemoteCollection } from 'utils/remote-collection.hook';
import { fetchManagersDropdown } from './api';
import { useLinkedManagers } from 'entities/company/linked-managers/model';
import { useStateSelector } from 'shared/store';

type UserPickerFieldProps = {
    excludedManagers?: number[]
    isLinkedToCompany?: boolean
    includeUser?: boolean
    clearable?: boolean
    withNonSelectOption?: boolean;
    nonSelectLabel?: string;
    size?: 'small' | 'medium' | undefined;
} & TextFieldProps

export const UserPickerField = (props: UserPickerFieldProps) => {
  const {
    form: { setFieldValue, initialValues },
    field: { name, value },
    size =  undefined,
  } = props;

  const {
    isLinkedToCompany = false,
    includeUser = false,
    clearable = false,
    withNonSelectOption = false,
    nonSelectLabel,
    excludedManagers,
    ...textFieldProps } = props;

  const initialId: number | null = initialValues[name] ?? null;
  //todo: есть мнение, что айдишник выбранного менеджера можно достать из value.
  // Тогда не надо будет синхронизировать стейт компонента и состояние формика
  const [selectedManagerId, setSelectedManagerId] = useState<number | null>(initialId);
  const currentUser = useStateSelector(store => store.auth.user);

  const onChange =
    (_event: SyntheticEvent<Element, Event>, newValue: IManager | null) => {
      if (!newValue && !clearable) return;
      setSelectedManagerId(newValue ? newValue.id : null);
    };
  const [ open, setOpen ] = useState(false);
  const { items: allManagers, isLoading: fetching } = useManagersDropdownList();
  const { links: { items: linkedManagers } } = useLinkedManagers();

  const managers = useMemo(() => {
    const filteredManagers = isLinkedToCompany ?
      linkedManagers.filter(managerInfo => managerInfo.linkType === 'link')
        .map(({ manager }) => manager) :
      allManagers;

    if (includeUser && currentUser) {
      const isUserInManagers = filteredManagers.some((item: IManager) => item.id === currentUser.id);

      if (!isUserInManagers) {
        filteredManagers.push(currentUser);
      }
      setSelectedManagerId(currentUser.id);
    }

    const nonSelectOption = {
      firstName: `- ${nonSelectLabel || 'Не выбран'}`,
      secondName: '-',
      id: 0
    };
    return withNonSelectOption ? [nonSelectOption, ...filteredManagers] : [...filteredManagers];
  }, [isLinkedToCompany, linkedManagers, allManagers, includeUser, currentUser, nonSelectLabel, withNonSelectOption]);

  const loading = open && managers.length === 0 && fetching;

  useEffect(() => {
    setFieldValue(name, (selectedManagerId || selectedManagerId === 0) ? selectedManagerId : null);
  }, [selectedManagerId, setFieldValue, name]);

  const selectedManager: IManager | null = managers.find(x => (x.id === selectedManagerId || selectedManagerId === 0)) || null;
  return (
    <Autocomplete
      fullWidth
      value={ selectedManager}
      options={managers}
      disabled={props.disabled}
      disableClearable={!clearable}
      open={open}
      size={size}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => ((option.id === value.id) || (isNaN(option.id) && isNaN(value.id)))}
      getOptionLabel={(option) => `${option.firstName} ${option.secondName}`}
      getOptionDisabled={(o) => excludedManagers ? excludedManagers.includes(o.id) : false}
      loading={loading}
      loadingText={'Загрузка...'}
      noOptionsText={'Ничего не найдено'}
      renderInput={
        (params) => <TextField
          {...fieldToTextField(textFieldProps)}
          name={name}
          {...params}
          disabled={props.disabled}
          label={ textFieldProps.label ?? 'Менеджер' }
          placeholder="Начните набирать имя..."/>
      }
    />);
};


export const useManagersDropdownList = () => {
  return useReadOnlyRemoteCollection<IManager>({
    fetchHandler: useCallback(() => fetchManagersDropdown(), [])
  });
};
