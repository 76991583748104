import { Type } from "class-transformer";
import { IsString, IsNumber, IsEnum } from 'class-validator';
import { WccStatus} from "./enums/wcc-statuses";
import { InvoiceDto } from "./invoice.dto";
import { SignerDto } from './signer.dto';

export class WccDto {
  @IsNumber()
  id: number;

  @IsString()
  wccNumber: string;

  date: string;

  status: WccStatus;

  @Type()
  invoice: InvoiceDto;

  amount: number;
  sellerSignerId: number;

  @Type()
  sellerSigner: SignerDto;
  aktFile?: File | null;
  updFile?: File | null;
  otherFiles?: File[];
}

export class UpdateWccDto {
  @IsNumber()
  id: number;

  @IsEnum(WccStatus)
  status: WccStatus;

  @Type()
  date: Date;

  amount: number;

  sellerSignerId: number;
}


export class InvoiceWccDto {
  wcc: WccDto
}

export class CreateInvoiceWccDto {
  @IsNumber()
  invoiceId: number;
}

export class CreateInvoiceWccSupplierDto {
  @IsNumber()
  invoiceId: number;
  
  @IsString()
  wccNumber: string;

  date: Date;

  amount: number;
}

export class UpdateInvoiceWccSupplierDto {
  @IsNumber()
  id: number;

  date: Date | string;

  @IsEnum(WccStatus)
  status: WccStatus;

  amount: number;

  aktFile?: File | null;
  updFile?: File | null;
  otherFiles?: File[];
}

export abstract class UpdateInvoiceWccSupplierAbstractDto {
  id: number;

  date: Date;

  status: WccStatus;

  amount: number;
}