import { Stack, MenuItem, TextField, Typography, Switch, FormControlLabel } from '@mui/material';
import { CreateLegalEntityDto, LegalEntityDto, UpdateLegalEntityDto } from '@sr/dto';
import { useLegalEntityTypesActiveList } from './legal-entities-type/legal-entities-type.hooks';

export interface FormData {
  name: string;
  legalEntityTypeId: number | '';
  inn: string;
  kpp: string;
  address: string;
  generateWcc: boolean;
  postAddress: string;
  vat?: number | null;
  noVat?: boolean;
}

export const fromLegalEntity = (entity: LegalEntityDto): FormData => {
  return {
    legalEntityTypeId: entity.legalEntityTypeId,
    name: entity.name,
    inn: entity.inn,
    kpp: entity.kpp,
    address: entity.address,
    postAddress: entity.postAddress,
    generateWcc: entity.generateWcc,
    vat: entity.vat ?? null,
    noVat: entity.vat === null,
  };
};

export const toLegalEntity = (id: number, formData: FormData): UpdateLegalEntityDto => {
  return {
    id,
    ...toNewLegalEntity(formData)
  };
};

export const toNewLegalEntity = (formData: FormData): CreateLegalEntityDto => {
  // if (formData.noVat) formData.vat = null
  return {
    legalEntityTypeId: formData.legalEntityTypeId as number,
    name: formData.name,
    inn: formData.inn,
    kpp: formData.kpp,
    address: formData.address,
    postAddress: formData.postAddress,
    generateWcc: formData.generateWcc,
    vat: formData.noVat ? null : formData.vat,
    noVat: formData.noVat
  };
};

export const emptyValues: FormData = {
  name: '',
  legalEntityTypeId: '',
  inn: '',
  kpp: '',
  address: '',
  postAddress: '',
  generateWcc: true,
  vat: null,
  noVat: true
};

export const validate = (values: FormData) => {
  let errors: Partial<{ [x in keyof FormData]: string }> = {};

  const requiredField = 'Обязательное поле';

  if (!values.legalEntityTypeId)
    errors.legalEntityTypeId = requiredField;

  if (!values.name)
    errors.name = requiredField;

  if (!values.inn)
    errors.inn = requiredField;
  else {
    if (!(values.inn.length === 10 || values.inn.length === 12))
      errors.inn = 'ИНН должен состоять из 10 или 12 цифр';
    if (!(/^\d{10,12}$/).test(String(values.inn)))
      errors.inn = 'ИНН должно состоять из цифр (10 или 12 цифр)';
  }

  if (!values.kpp)
    errors.kpp = requiredField;
  else {
    if (!(values.kpp.length === 9))
      errors.kpp = 'КПП должен состоять из 9 цифр';
    if (!(/^\d{9}$/).test(String(values.kpp)))
      errors.kpp = 'КПП должен состоять из цифр (9 цифр)';
  }

  if (!values.address)
    errors.address = requiredField;

  if (!values.postAddress)
    errors.postAddress = requiredField;

  if (!(/^\d{1,2}$/).test(String(values.vat)) && !(values.noVat))
    errors.vat = 'Введенное значение не является числом (не более 2 цифр)';

  return errors;
};

type Props = {
  formik: any;
}

export const LegalEntityBasicForm = (props: Props) => {
  const { formik } = props;
  const { collection, isLoading, error } = useLegalEntityTypesActiveList();
  let arr: any = []
  collection.map((el: any) => (el.active || formik.values.legalEntityTypeId == el.id) ? arr.push(el) : '')
  return (
    <>
      <form>
        <Stack direction="column" spacing={2}>
          <TextField
            name="name"
            label="Название"
            onChange={formik.handleChange}
            value={formik.values.name}
            error={formik.touched.name && !!formik.errors.name}
            helperText={formik.errors.name}
          />
          <TextField
            name="legalEntityTypeId"
            defaultValue={null}
            onChange={formik.handleChange}
            value={formik.values.legalEntityTypeId}
            select
            label="Форма собственности"
            error={formik.touched.legalEntityTypeId && !!formik.errors.legalEntityTypeId}
            helperText={formik.errors.legalEntityTypeId}
          >
            {
              arr.map((el: any) =>
                <MenuItem key={el.id} value={el.id} disabled={!el.active}>
                  {el.name}&nbsp;
                  <Typography component="span" color="text.secondary">
                    ({el.description})
                  </Typography>
                </MenuItem>
              )
            }
          </TextField>

          <Stack direction="row" spacing={2} >
            <TextField
              name="inn"
              label="ИНН"
              onChange={formik.handleChange}
              value={formik.values.inn}
              error={formik.touched.inn && !!formik.errors.inn}
              helperText={formik.errors.inn}
            />
            <TextField
              name="kpp"
              label="КПП"
              onChange={formik.handleChange}
              value={formik.values.kpp}
              error={formik.touched.kpp && !!formik.errors.kpp}
              helperText={formik.errors.kpp}
            />
          </Stack>
          <TextField
            name="address"
            label="Адрес"
            onChange={formik.handleChange}
            value={formik.values.address}
            error={formik.touched.address && !!formik.errors.address}
            helperText={formik.errors.address}
          />
          <TextField
            name="postAddress"
            label="Почтовый адрес"
            onChange={formik.handleChange}
            value={formik.values.postAddress}
            error={formik.touched.postAddress && !!formik.errors.postAddress}
            helperText={formik.errors.postAddress}
          />
          <FormControlLabel
            name="noVat"
            control={<Switch onChange={formik.handleChange} checked={formik.values.noVat} />}
            label='без НДС' />
          <TextField
            name="vat"
            label="Ставка НДС %"
            onChange={formik.handleChange}
            value={
              formik.values.noVat
                ? "без НДС"
                : formik.values.vat === null ? '' : formik.values.vat
            }
            error={formik.touched.vat && !!formik.errors.vat}
            helperText={formik.errors.vat}
            disabled={formik.values.noVat}
          />
          <FormControlLabel
            control={
              <Switch
                name='generateWcc'
                onChange={formik.handleChange}
                checked={formik.values.generateWcc}
              />
            }
            label="Формировать акты"
          />
        </Stack>
      </form>
    </>
  );
};
