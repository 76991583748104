import { FormDataFileField } from '../../../utils/form-tools';

export interface FormData {
  legalEntityId: number;
  titleForm1: string;
  titleForm2: string;
  titleForm3: string;
  positionForm1: string;
  positionForm2: string;
  positionForm3: string;
  basedOn: string;
  signature: FormDataFileField,
}

export const initialValues: FormData = {
  legalEntityId: NaN,
  titleForm1: '',
  titleForm2: '',
  titleForm3: '',
  positionForm1: '',
  positionForm2: '',
  positionForm3: '',
  basedOn: '',
  signature: null,
};

type ValidationData = Partial<Record<keyof FormData, string>>;

export const validate = (values: FormData) => {
  const errors: ValidationData = {};

  const requiredMsg = 'Поле обязательно';
  if (!values.titleForm1) errors.titleForm1 = requiredMsg;

  if (!values.basedOn) errors.basedOn = requiredMsg;

  return errors;
};
