import { LeadDto } from '@sr/dto';
import { Box, Button } from '@mui/material';
import { CardPaperTitled } from 'shared/ui/card-paper';
import { ErrorBanner } from 'shared/ui/error-banner';
import { useState } from 'react';
import MailIcon from '@mui/icons-material/Mail';
import { ViewEmailModal } from 'components/Companies/Feed/view-email-modal';
import AudioPlayer from '../../../entities/audio-player/audio-player';

type EmailData = {
  from: string;
  to: string;
  subject: string;
};

type AtsCallData = {
  direction: string;
  correspondentNumber: string;
  timeStamp: Date;
  ourNumber: string;
  duration: number;
  waitingTime: number;
  audioUrl: string;
};

const EmailPreview = ({ content }: { content: string }) => {
  try {
    const emailData: EmailData = JSON.parse(content);
    const html = `<b>Письмо от ${emailData.from} на ${emailData.to}</b><br/><p>Тема: ${emailData.subject}</p>`;
    return <Box dangerouslySetInnerHTML={{ __html: html }} />;
  } catch (e) {
    console.log(e);
    return <ErrorBanner errorMessage="Ошибка предпросмотра емейла" />;
  }
};

const PhoneCallPreview = ({ content }: { content: string }) => {
  try {
    const callData: AtsCallData = JSON.parse(content);
    console.log('callData', callData);

    let direction;
    switch (callData.direction) {
      case 'in':
        direction = 'Входящий звонок';
        break;
      case 'out':
        direction = 'Исходящий звонок';
        break;
      case 'missed':
        direction = 'Пропущенный звонок';
        break;
      default:
        throw new Error('Unknown call direction');
    }

    const html = `<b>${direction} ${callData.duration} секунд.</b><br/><p>Время ожидания: ${callData.waitingTime} секунд.</p><p>Наш номер: ${callData.ourNumber}</p><p>Номер собеседника: ${callData.correspondentNumber}</p>`;
    return (
      <>
        <Box dangerouslySetInnerHTML={{ __html: html }} />
        <AudioPlayer audioUrl={callData.audioUrl} />
      </>
    );
  } catch (e) {
    console.log(e);
    return <ErrorBanner errorMessage="Ошибка предпросмотра звонка АТС" />;
  }
};

export const LeadContentWidget = ({ lead }: { lead: LeadDto }) => {
  const [openEmailModal, setOpenEmailModal] = useState<boolean>(false);
  return (
    <CardPaperTitled title="Содержание">
      {!lead.emailId && !lead.atsEntryId ? (
        <Box sx={{ wordBreak: 'break-word' }}>{lead.content}</Box>
      ) : lead.emailId ? (
        <EmailPreview content={lead.content} />
      ) : (
        <PhoneCallPreview content={lead.content} />
      )}
      {lead.emailId && (
        <Button variant="outlined" onClick={() => setOpenEmailModal(true)} endIcon={<MailIcon />}>
          Email
        </Button>
      )}
      {lead.emailId && openEmailModal && <ViewEmailModal onClose={() => setOpenEmailModal(false)} open={openEmailModal} id={lead.emailId} />}
    </CardPaperTitled>
  );
};
