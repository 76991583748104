import { IsNotEmpty, IsNumber, IsNumberString, IsString, Length } from "class-validator";

export class CreateSignerDto {
  @IsNumber()
  legalEntityId: number = NaN;

  @IsString()
  @IsNotEmpty({ message: 'Не должно быть пустым' })
  titleForm1: string = '';

  @IsString()
  titleForm2: string = '';

  @IsString()
  titleForm3: string = '';

  @IsString()
  positionForm1: string = '';

  @IsString()
  positionForm2: string = '';

  @IsString()
  positionForm3: string = '';

  @IsString()
  basedOn: string = '';

  signature: File | null;
}

export class SignerDto extends CreateSignerDto {
  id: number;
  signatureFileId: number;
}

export class UpdateSignerDto extends CreateSignerDto {
  id: number;
}
