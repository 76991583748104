import { Box, Divider, Stack } from '@mui/material';
import { Field, FormikHelpers } from 'formik';
import { TextField } from 'formik-mui';
import { FormValues, validate } from './company-dialog.form';
import { FormDialog } from 'shared/ui/form-dialog';
import { CompanyTypesCheckboxes } from 'entities/company/company-type-field';
import { ContactsListField } from 'features/contacts-form-field';
import { useStateSelector } from 'shared/store';
import { CityPicker } from '../../../entities/geo/cities/city-picker';

export interface DialogProps<T> {
  open: boolean;
  onClose: () => void;
  onSubmit: (newCompanyData: T) => Promise<void>;
}

interface CoreDialogProps {
  title: string;
  isCreateMode?: boolean;
  open: boolean;
  onClose: () => void;
  handleSubmit: (values: FormValues, helpers?: FormikHelpers<FormValues>) => Promise<void>;
  formInitialValues: () => FormValues;
}

export const CompanyDialogCore = ({ open, onClose, handleSubmit, title, formInitialValues, isCreateMode }: CoreDialogProps) => {
  const isDupesCheckInprogress = useStateSelector((state) => state.ui.dupesIndication.isDupesCheckInProgress);

  return (
    <FormDialog
      onClose={onClose}
      open={open}
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
      renderTitle={title}
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={formInitialValues()}
      validateOnChange
      options={{ disableOkButton: isDupesCheckInprogress }}
      renderForm={() => (
        <Stack direction="column" spacing={2}>
          <Field component={TextField} name="name" label="Название" sx={{ mt: 1 }} />
          <Box flexGrow={1}>
            <Field component={CityPicker} name="cityId" label="Город" />
          </Box>
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Box flexGrow={1}>
              <Field component={CompanyTypesCheckboxes} name="companyType" />
            </Box>

            {isCreateMode && (
              <>
                <Box>
                  <Divider orientation="vertical" />
                </Box>
                <Box flexGrow={1}>
                  <Field name="companyContacts" component={ContactsListField} />
                </Box>
              </>
            )}
          </Stack>
        </Stack>
      )}
    />
  );
};
