import { IPagedCollection, LeadBulkUpdateDto, LeadDto } from '@sr/dto';
import { CollectionParams } from '../../shared/api/rtk-query';
import { LEAD_BASE_URL } from '../../shared/api/api';
import { pagingDataToParams } from '../../utils/remote-paged-collection.hook';
import { leadBaseApi } from './api';

const injectedLeadsListApi = leadBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getLeadsList: builder.query<IPagedCollection<LeadDto>, CollectionParams>({
      query: ({ paging, filter }) => ({
        url: `${LEAD_BASE_URL}/?${filter}`,
        params: paging && pagingDataToParams(paging)
      }),
    }),
    bulkUpdateLeads: builder.mutation<LeadDto, LeadBulkUpdateDto>({
      query: (dto) => ({
        url: `${LEAD_BASE_URL}`,
        body: dto,
        method: 'PUT',
      }),
    }),
  }),
});

export const leadsListApi = injectedLeadsListApi.enhanceEndpoints({
  addTagTypes: ['lead-list'],
  endpoints: {
    getLeadsList: {
      providesTags: (result, error, query) => (
        result ?
          [{
            type: 'lead-list',
          }]
          : ['lead-list']
      ),
    },
    bulkUpdateLeads: {
      invalidatesTags:[{ type: 'lead-list' }]
    }
  }
});
