import { Typography } from '@mui/material';
import { LinkToLegalEntity } from 'shared/ui/links/link-to-legal-entity';
import { LinkToCompany } from 'shared/ui/links/link-to-company';
import { CompanyDto, LegalEntityDto } from '@sr/dto';
import { LegendItem, LegendList } from 'shared/ui/legend-list';
import { CardPaper } from 'shared/ui/card-paper';

type Props = {
  title: string;
  company?: CompanyDto,
  legalEntity?: LegalEntityDto;
};

export const AgentCard = ({ title, company, legalEntity }: Props) => {
  return (
    <CardPaper>
      <Typography variant='h6'>{title}</Typography>
      <LegendList spacing={0}>
        {company && <LegendItem title="Фирма: " value={<LinkToCompany company={company} />}></LegendItem>}
        {legalEntity && <LegendItem title="Юрлицо:" value={<LinkToLegalEntity legalEntity={legalEntity} />} />}
        {legalEntity && <LegendItem title="НДС:" value={legalEntity.vat !== null ? legalEntity.vat + "%" : 'без НДС'} />}
      </LegendList>
    </CardPaper >
  );
};