import { TextField, Autocomplete } from '@mui/material';
import { SignerDto } from '@sr/dto';
import { TextFieldProps, fieldToTextField } from 'formik-mui';
import { SyntheticEvent, useCallback, useState } from 'react';
import { IRemoteCollection } from '../../../utils/remote-collection.hook';

export const SignerPicker = (props: TextFieldProps & { legalEntityId?: number, collection: IRemoteCollection<SignerDto> }) => {
  const {
    form: { setFieldValue },
    field: { name, value },
    disabled,
    label,
    legalEntityId,
    collection
  } = props;

  const [open, setOpen] = useState(false);

  const onChange = useCallback(
    (_event: SyntheticEvent<Element, Event>, newValue: SignerDto | null) => {
      setFieldValue(name, newValue?.id);
    },
    [setFieldValue, name]
  );
  const loading = open && collection?.items?.length === 0 && collection?.isLoading;
  if (loading) {
    return null;
  }
  const selectedSigner = collection?.items?.find(x => x.id === value) ?? null;


  const {
    legalEntityId: _,
    ...textFieldProps } = props;

  return (
    <Autocomplete
      value={selectedSigner as (SignerDto | undefined)}
      autoComplete={false}
      options={collection?.items || []}
      open={open}
      disableClearable={true}
      disabled={disabled}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.titleForm1}, ${option.positionForm1}`}
      loading={loading}
      loadingText={'Загрузка...'}
      noOptionsText={legalEntityId && isNaN(legalEntityId) ? 'Укажите юрлицо' : 'Ничего не найдено'}
      renderInput={
        (params) => <TextField
          {...fieldToTextField(textFieldProps)}
          {...params}
          name={name}
          label={label}
          placeholder="Начните набирать..." />
      }
    />);
};


