import {
  Box,
  Stack,
} from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { useEffect } from 'react';
import { FormData } from './signers.form';
import { morpher } from 'shared/morpher/morpher.service';
import FilePickerField from '../../../shared/ui/formik-fields/file-picker';

export const SignerFormFields = () => {

  const {
    values: { positionForm1, titleForm1 },
    setFieldValue,
  } = useFormikContext<FormData>();

  useEffect(() => {
    setFieldValue('positionForm2', morpher(positionForm1, 'родительный', 'Должность'));
    setFieldValue('positionForm3', morpher(positionForm1, 'дательный', 'Должность'));
    setFieldValue('titleForm2', morpher(titleForm1, 'родительный', 'ФИО'));
    setFieldValue('titleForm3', morpher(titleForm1, 'дательный', 'ФИО'));
  }, [positionForm1, titleForm1, setFieldValue]);

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction='row' spacing={2}>
        <Box>
          <Stack direction="column" spacing={2} width={400}>

            <Field
              required
              label='ФИО руководителя (в именительном падеже)'
              placeholder='Иванов Иван Иванович'
              component={TextField}
              name='titleForm1'
            />
            <Field
              label='ФИО руководителя (в родительном падеже):'
              placeholder='Иванова Ивана Ивановича'
              component={TextField}
              name='titleForm2'
            />
            <Field
              label='ФИО руководителя (в дательном падеже)'
              placeholder='Иванову Ивану Ивановичу'
              component={TextField}
              name='titleForm3'
            />
            <Field
              required
              label='Действует на основании...'
              component={TextField}
              name='basedOn' />
          </Stack>
        </Box>

        <Stack direction="column" spacing={2} width={400}>
          <Field
            required
            label='Должность руководителя (в именительном падеже)'
            placeholder='директор'
            component={TextField}
            name='positionForm1'
          />
          <Field
            label='Должность руководителя (в родительном падеже)'
            placeholder='директора'
            component={TextField}
            name='positionForm2'
          />
          <Field
            label='Должность руководителя (в дательном падеже)'
            placeholder='директору'
            component={TextField}
            name='positionForm3'
          />
        </Stack>
    </Stack>
    <Stack width={400}>
      <Field
        name="signature"
        label="Подпись (без печати)"
        component={FilePickerField}
      />
    </Stack>
  </Stack>
  );
}
