import { useMemo } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { GridLayout } from '../../../shared/ui/grid-layout';
import { useSummary } from '../../../entities/salary/salary-entries/salary-entries.hook';
import { useSearchParams } from 'react-router-dom';
import { useUsersList } from '../../../entities/user/users-list/users-list.hook';
import { SALARY_ENTRY_TYPES, SalarySummaryDto } from '@sr/dto';
import { TotalsRow } from './totals-row';
import PriceSpan from '../../../shared/ui/price-span';
import { useSummaryTableOptions } from './use-summary-table-options';

export const SummaryTable = () => {
  const [searchParams] = useSearchParams();
  const year = searchParams.get('year') ?? new Date().getFullYear();
  const month = searchParams.get('month') ?? new Date().getMonth();

  const { columns, getSpanSize, getRowTotalColor, getPriceColor, paging } = useSummaryTableOptions();
  const { rows, error, isLoading: summaryIsLoading } = useSummary(Number(year), Number(month));
  const { collection, isLoading: userIsLoading } = useUsersList(paging);

  const data = useMemo(() => {
    // Explicitly type `total` as a Record of SALARY_ENTRY_TYPES values to numbers
    const total: Record<SALARY_ENTRY_TYPES | 'total', number> = Object.values(SALARY_ENTRY_TYPES).reduce(
      (acc, key) => ({ ...acc, [key]: 0 }),
      {} as Record<SALARY_ENTRY_TYPES | 'total', number>,
    );

    total.total = 0;
    const sumRows = collection.items?.map((value) => {
      const sum = rows?.find((row) => row.userId === value.id);

      // Explicitly type `items` as a Record of SALARY_ENTRY_TYPES values to numbers
      const items: Record<SALARY_ENTRY_TYPES, number> = {} as Record<SALARY_ENTRY_TYPES, number>;
      (Object.keys(total) as Array<SALARY_ENTRY_TYPES>).forEach((key) => {
        items[key] = sum ? sum[key] : 0;
      });

      total.total += sum?.total || 0;
      return {
        ...items,
        total: sum?.total || 0,
        userId: value.id,
        firstName: value.firstName,
        secondName: value.secondName,
      } as SalarySummaryDto;
    });

    return { total, rows: sumRows };
  }, [collection.items, rows]);

  return (
    <GridLayout
      columns={columns}
      items={data.rows}
      isLoading={summaryIsLoading || userIsLoading}
      error={error}
      noItemsText="Списокпуст"
      totalItemsCount={data.rows.length}
      newItemRowRender={() => <TotalsRow totals={data.total} rowColor={getRowTotalColor(data.total.total)} />}
      itemRender={(item: SalarySummaryDto) => {
        return <SummaryRow key={`SummaryRow as ${item.userId}`} summaryItem={item} getSpanSize={getSpanSize} getPriceColor={getPriceColor} />;
      }}
    />
  );
};

type RowProps = {
  summaryItem: SalarySummaryDto & {
    userId: number;
    firstName: string;
    secondName: string;
  };
  getSpanSize: (price: number) => 'small' | 'regular';
  getPriceColor: (price: number, type: 'addition' | 'deduction') => string | undefined;
};

const SummaryRow = ({ summaryItem, getSpanSize, getPriceColor }: RowProps) => {
  const additions = [
    summaryItem.base_salary,
    summaryItem.commission,
    summaryItem.seek_compensation,
    summaryItem.vacation_compensation,
    summaryItem.bonus,
    summaryItem.other_additions,
    summaryItem.prepayment,
  ];
  const deductions = [summaryItem.taxes, summaryItem.penalty, summaryItem.other_deductions];
  return (
    <TableRow key={`tableRow as ${summaryItem.userId}`}>
      <TableCell sx={{ p: 1.5 }}>
        {summaryItem.secondName} {summaryItem.firstName.charAt(0)}.
      </TableCell>
      {additions.map((item, index) => (
        <TableCell key={`additionSpan + ${index} + ${item}`}>
          <PriceSpan size={getSpanSize(item)} price={item} color={getPriceColor(item, 'addition')} coloring={false} />
        </TableCell>
      ))}
      {deductions.map((item, index) => (
        <TableCell key={`deductionSpan +  ${index} + ${item}`}>
          <PriceSpan size={getSpanSize(item)} price={item} color={getPriceColor(item, 'deduction')} coloring={false} />
        </TableCell>
      ))}
      <TableCell>
        <PriceSpan
          size={getSpanSize(summaryItem.total)}
          price={summaryItem.total}
          coloring={false}
          color={getPriceColor(summaryItem.total, summaryItem.total > 0 ? 'addition' : 'deduction')}
        />
      </TableCell>
    </TableRow>
  );
};
