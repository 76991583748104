import { INVOICE_TYPE_CLIENT, InvoiceDto } from '@sr/dto';
import { useInvoiceMutableData } from 'entities/invoice/mutable-data/lib';
import { HeaderText } from 'shared/ui/header-text';
import { formatDate } from 'utils/date-format-helpers';

export const InvoiceTitle = ({ invoice }: { invoice: InvoiceDto }) => {
  const mutableData = useInvoiceMutableData();

  if (!invoice) return null;
  if (!mutableData) return null;

  const companyName = invoice.type === INVOICE_TYPE_CLIENT
    ? invoice.buyerCompany.name
    : invoice.sellerCompany.name;

  return invoice.type === INVOICE_TYPE_CLIENT
    ?
    <HeaderText>
      Исходящий cчет №<HeaderText.Stressed>{invoice.invoiceNumber}</HeaderText.Stressed>
      <> для компании <HeaderText.Stressed>{companyName}</HeaderText.Stressed></>
    </HeaderText>
    :
    <HeaderText>
      Входящий cчет №<HeaderText.Stressed>{invoice.invoiceNumber}</HeaderText.Stressed>
      <> от <HeaderText.Stressed>{formatDate(mutableData.startDate)}</HeaderText.Stressed></>
      <> от компании <HeaderText.Stressed>{companyName}</HeaderText.Stressed></>
    </HeaderText>;
};
