import { CONTRACT_TYPE_CLIENT, ContractDto } from '@sr/dto';
import { LegendList, LegendItem } from 'shared/ui/legend-list';
import { LinkToLegalEntity } from 'shared/ui/links/link-to-legal-entity';
import { CardPaper } from 'shared/ui/card-paper';

export const PartiesCard = ({ contract }: { contract: ContractDto; }) => {
  const ourRole = contract.type === CONTRACT_TYPE_CLIENT ? 'seller' : 'buyer';
  const otherRole = contract.type === CONTRACT_TYPE_CLIENT ? 'buyer' : 'seller';
  return (
    <CardPaper>
      <LegendList>
        <LegendItem title={`Юрлицо ${contract.type === CONTRACT_TYPE_CLIENT ? 'клиента' : 'поставщика'}:`} value={<LinkToLegalEntity legalEntity={contract[otherRole]} />} />
        <LegendItem title="Наше юрлицо:" value={<LinkToLegalEntity legalEntity={contract[ourRole]} />} />
      </LegendList>
    </CardPaper>
  );
};
