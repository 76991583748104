import { useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BlockIcon from '@mui/icons-material/Block';
import EditIcon from '@mui/icons-material/Edit';
import { GridLayout } from 'shared/ui/grid-layout';
import { BankAccountDto, BankAccountType, BA_TYPES, CreateBankAccountDto, UpdateBankAccountDto } from '@sr/dto';
import { Button, IconButton, TableRow, TableCell, Typography, Box, Stack, FormControlLabel, MenuItem, Switch } from '@mui/material';
import { FormData, formTools } from './bank-account.form-tools';
import { NotificationsConfig } from 'shared/ui/snack';
import { useCreateOrEditDialog } from 'hooks/create-or-edit-dialog.hook';
import { useBankAccounts } from 'hooks/bank-accounts.hook';
import { FormDialog } from 'shared/ui/form-dialog';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { LegalEntityPicker } from 'shared/ui/formik-fields/legal-entity-picker';
import { useOurCompany } from 'hooks/portal-settings.hook';
import { BankDetailsPicker } from 'shared/ui/formik-fields/bank-details-picker';
import { useBankDetailsDialog } from '../../../shared/providers/legal-entities.provider';

const columns = ['Активен', 'Номер', 'Тип', ''];

const notificationsConfig: NotificationsConfig = {
  itemTitleForm1: 'расчетный счет',
  itemTitleForm2: 'расчетного счета',
  gender: 'male',
};

const BankAccountsList = () => {
  const { collection, addItem, updateItem } = useBankAccounts();
  const [legalEntityId, setLegalEntityId] = useState(0);
  const { dialogProps, openToAdd, openToEdit, itemToEdit } = useCreateOrEditDialog<BankAccountDto, CreateBankAccountDto, UpdateBankAccountDto, FormData>({
    addHandler: async (dto) => {
      await addItem(dto);
    },
    updateHandler: async (dto) => {
      await updateItem(dto.id, dto);
    },
    formTools: formTools,
    notificationsConfig,
  });
  const ourCompanyId = useOurCompany();
  const ourBankDetailsDialog = useBankDetailsDialog(legalEntityId);

  return (
    <Box>
      <Button variant="contained" sx={{ mb: 2 }} onClick={openToAdd}>
        Добавить
      </Button>

      <GridLayout<BankAccountDto>
        columns={columns}
        items={collection.items}
        isLoading={collection.isLoading}
        error={collection.error}
        itemRender={(account) => (
          <Row
            key={account.id}
            account={account}
            onEditClick={() => openToEdit(account)}
            // onDeleteClick={() => handleDelete(account)}
          />
        )}
        noItemsText="Список счетов пуст"
      />

      <FormDialog<FormData>
        {...dialogProps}
        renderTitle={!itemToEdit ? 'Новый расчетный счет' : 'Расчетный счет'}
        renderForm={(props) => {
          const legalEntityIdFilter = props.values.legalEntityId;
          setLegalEntityId(legalEntityIdFilter as number);
          return (
            <Stack direction="column">
              <Box>{itemToEdit && <Field name="isActive" control={<Switch />} type="checkbox" as={FormControlLabel} label="Активен" />}</Box>

              <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                <Field required name="title" component={TextField} label="Расчетный счет" fullWidth />
                <Field name="type" component={TextField} select fullWidth label="Тип" sx={{ width: 250 }}>
                  {/* <MenuItem value=''>Выберите тип...</MenuItem> */}
                  {Object.keys(BA_TYPES).map((key) => (
                    <MenuItem key={key} value={key}>
                      {BA_TYPES[Number(key) as BankAccountType]}
                    </MenuItem>
                  ))}
                </Field>
              </Stack>
              <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                <Box sx={{ flexBasis: 250, flexGrow: 1 }}>
                  <Field label="Наше юр. лицо" component={LegalEntityPicker} companyIdFilter={ourCompanyId} fullWidth name="legalEntityId" />
                </Box>
                <Box sx={{ flexBasis: 250, flexGrow: 1 }}>
                  <Field
                    label="Наши реквизиты"
                    name="bankDetailsId"
                    fullWidth
                    component={BankDetailsPicker}
                    legalEntityId={legalEntityIdFilter}
                    collection={ourBankDetailsDialog.collection}
                    disabled={!legalEntityIdFilter}
                  />
                </Box>
              </Stack>
            </Stack>
          );
        }}
      />
    </Box>
  );
};

type Props = {
  account: BankAccountDto;
  onEditClick: () => void;
};

const Row = ({ account, onEditClick }: Props) => {
  return (
    <TableRow>
      <TableCell>{account.isActive ? <CheckCircleIcon color="primary" /> : <BlockIcon sx={{ color: 'grey.500' }} />}</TableCell>
      <TableCell>
        <Typography variant="body2" color={account.isActive ? 'inherit' : 'grey.500'}>
          {account.title}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" color={account.isActive ? 'inherit' : 'grey.500'}>
          {BA_TYPES[account.type]}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <IconButton edge="end" onClick={onEditClick}>
          <EditIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default BankAccountsList;
