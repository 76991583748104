import { Typography, Stack } from '@mui/material';
import { useSalaryPeriods } from './salary-period.hook';
import { ErrorBanner } from 'shared/ui/error-banner';
import { PeriodPicker } from 'entities/period-picker/period-picker';
import { Period } from 'entities/period-picker/types';
import { getCurrentPeriod } from 'entities/period-picker/period.helpers';
import { ReactNode } from 'react';
import { InputSize } from 'shared/layout/constants';
import { ManagerUrlParamsFilter } from '../../filters/manager-url-params-filter';

interface Props {
  title?: ReactNode;
  selectedPeriod?: Period;
  onSelect: (period: Period) => void;
  size?: InputSize;
  readOnly?: boolean;
  withManager?: boolean;
  withQueryParams?: boolean;
}

export const SalaryPeriodPicker = ({
  title,
  size = 'small',
  selectedPeriod = getCurrentPeriod(),
  onSelect,
  readOnly = false,
  withManager = false,
  withQueryParams = false,
}: Props) => {
  const { salaryPeriods, isLoading, error } = useSalaryPeriods();
  if (error) {
    return <ErrorBanner errorMessage={error} />;
  }

  return (
    <>
      {title && (
        <Typography variant="subtitle1" sx={{ my: 1 }}>
          {title}
        </Typography>
      )}
      {withManager ? (
        <Stack sx={{ gap: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: '600px', '& .MuiInputBase-root': { backgroundColor: '#fff' } }}>
          {!isLoading && (
            <PeriodPicker
              withManager={withManager}
              size={size}
              periods={salaryPeriods}
              onSelect={onSelect}
              selectedPeriod={selectedPeriod}
              isLoading={isLoading}
              withQueryParams={withQueryParams}
              readOnly={readOnly}
            />
          )}
          <ManagerUrlParamsFilter queryParamName={'manager'} withNonSelect={false} label={'Менеджер'} />
        </Stack>
      ) : (
        <PeriodPicker
          size={size}
          periods={salaryPeriods}
          onSelect={onSelect}
          selectedPeriod={selectedPeriod}
          isLoading={isLoading}
          withQueryParams={withQueryParams}
          readOnly={readOnly}
        />
      )}
    </>
  );
};
