import { IPagingData } from '@sr/dto';

export const useSummaryTableOptions = () => {
  const paging: IPagingData = { itemsPerPage: 100, page: 0 };

  const COLORS = {
    LIGHT_GREEN: '#f5fff5',
    LIGHT_RED: '#ffeded',
    DARK_GREEN: '#006540',
    DARK_RED: '#990000',
    NEUTRAL_GRAY: '#899499',
  };

  const getRowTotalColor = (total: number) => {
    if (total > 0) {
      return COLORS.LIGHT_GREEN;
    }
    if (total < 0) {
      return COLORS.LIGHT_RED;
    }
    return undefined;
  };

  const plusSpan = <span style={{ color: COLORS.DARK_GREEN }}>+</span>;
  const minusSpan = <span style={{ color: COLORS.DARK_RED }}>-</span>;

  const getPriceColor = (price: number, type: 'addition' | 'deduction') => {
    if (price === 0) {
      return COLORS.NEUTRAL_GRAY;
    }
    if (price > 0 && type === 'addition') {
      return COLORS.DARK_GREEN;
    }
    if (price > 0 && type === 'deduction') {
      return COLORS.DARK_RED;
    }
    return undefined;
  };

  const getSpanSize = (price: number) => {
    return price > 0 ? 'regular' : 'small';
  };

  const columns = [
    { label: 'Сотрудник' },
    { label: 'Оклад', color: COLORS.LIGHT_GREEN, additionalIcon: plusSpan },
    { label: '% по счетам', color: COLORS.LIGHT_GREEN, additionalIcon: plusSpan },
    { label: 'Больничные', color: COLORS.LIGHT_GREEN, additionalIcon: plusSpan },
    { label: 'Отпускные', color: COLORS.LIGHT_GREEN, additionalIcon: plusSpan },
    { label: 'Премии', color: COLORS.LIGHT_GREEN, additionalIcon: plusSpan },
    { label: 'Пр. начисления', color: COLORS.LIGHT_GREEN, additionalIcon: plusSpan },
    { label: 'Аванс', color: COLORS.LIGHT_GREEN, additionalIcon: plusSpan },
    { label: 'Налоги', color: COLORS.LIGHT_RED, additionalIcon: minusSpan },
    { label: 'Штраф', color: COLORS.LIGHT_RED, additionalIcon: minusSpan },
    { label: 'Пр. удержания', color: COLORS.LIGHT_RED, additionalIcon: minusSpan },
    { label: 'Итого' },
  ];

  return { columns, getSpanSize, getRowTotalColor, getPriceColor, paging };
};
