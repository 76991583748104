import { Field } from 'formik';
import { DatePickerField } from 'shared/ui/formik-fields/date-picker';
import { BaseUrlParamsFilter, BaseUrlParamsFilterProps } from './base-url-params-filter';

export const DateUrlParamsFilter = ({ queryParamName, label, size = 'medium' }: BaseUrlParamsFilterProps) => {
  return (
    <BaseUrlParamsFilter queryParamName={queryParamName}>
      <Field
        fullWidth
        name={queryParamName}
        label={label}
        size={size}
        clearable
        component={DatePickerField}
      />
    </BaseUrlParamsFilter>
  );
};
