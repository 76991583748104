import { plainToInstance, Type } from "class-transformer";
import { IsNumber, IsOptional, IsIn, IsBoolean, IsNotEmpty, Max, Min } from 'class-validator';
import {
  INVOICE_PRINT_NOT_SHOW_DATE,
  INVOICE_PRINT_SHOW_MONTH,
  INVOICE_PRINT_SHOW_ALL_DATE,
  InvoiceStatus,
  WccDto,
  InvoiceReportRowDto,
  PayOrderDto,
} from '../main';
import { CompanyDto } from "./company.dto";
import { ContractInfoDto } from "./contract.dto";
import { INVOICE_TYPE_CLIENT, INVOICE_TYPE_SUPPLIER } from "./enums/invoice-types";
import { LegalEntityDto } from "./legal-entity.dto";
import { UserDto } from "./user.dto";
import { ContractAppendixDto } from './contract-appendix.dto';

export type InvoiceTotals = {
  priceTotal: number;
  discountPriceTotal: number;
  partialPaidAmount: number;
  priceExclVatTotal: number;
  vatValueTotal: number;
}

export class InvoiceBasicResource {
  id: number;

  @Type()
  startDate: Date;

  @IsOptional()
  @Type()
  endDate?: Date;

  @IsNumber()
  managerId: number;

  @IsBoolean()
  printFormShowMediaLink: boolean;

  @IsNumber()
  @IsIn([INVOICE_PRINT_NOT_SHOW_DATE,INVOICE_PRINT_SHOW_MONTH,INVOICE_PRINT_SHOW_ALL_DATE])
  printFormShowDateViewState: number;

  @IsOptional()
  @IsBoolean()
  generateWcc?: boolean

  @IsOptional()
  @IsNumber()
  legalEntityTax: number;
  @IsBoolean()
  invoiceSupplier?: boolean

}

export class CreateInvoiceDto {
  @Type()
  startDate: Date;

  @IsOptional()
  @Type()
  endDate: Date;

  invoiceNumber?: string;

  @IsNumber()
  sellerCompanyId: number;

  @IsNumber()
  sellerLegalEntityId: number;

  @IsOptional()
  @IsNumber()
  sellerSignerId: number;

  @IsOptional()
  @IsNumber()
  sellerBankDetailsId: number;

  @IsNumber()
  buyerCompanyId: number;

  @IsNumber()
  buyerLegalEntityId: number;

  @IsOptional()
  @IsNumber()
  contractId: number;

  @IsNumber()
  @IsIn([INVOICE_TYPE_CLIENT, INVOICE_TYPE_SUPPLIER])
  type: number;

  @IsNumber()
  managerId: number;

  @IsOptional()
  @IsBoolean()
  generateWcc: boolean;
}

// export type InvoiceInfo = {
//   id: number;
//   invoiceNumber: string;
//   status: InvoiceStatus;
//   manager: UserDto;
//
//   sellerCompany: CompanyDto;
//   seller: LegalEntityDto;
//
//   buyerCompany: CompanyDto;
//   buyer: LegalEntityDto;
//
//   startDate: string;
//   endDate: string;
//
//   totals: InvoiceTotals;
// }

export class InvoiceInfoDto {
  @IsNumber()
  id: number;

  invoiceNumber: string;

  @Type(() => Number)
  status: InvoiceStatus;

  @Type(() => UserDto)
  manager: UserDto;

  @Type(() => CompanyDto)
  sellerCompany: CompanyDto;

  @Type(() => LegalEntityDto)
  seller: LegalEntityDto;

  @Type(() => CompanyDto)
  buyerCompany: CompanyDto;
  @Type(() => LegalEntityDto)
  buyer: LegalEntityDto;


  @Type(() => Date)
  startDate: Date;

  @IsOptional()
  @Type(() => Date)
  endDate?: Date;

  totals: InvoiceTotals;

  wcc: WccDto | null;

  report: {
    rows: InvoiceReportRowDto[];
    clientPayments: PayOrderDto[];
  } | null;
}

export class InvoiceDto extends CreateInvoiceDto {
  @IsNumber()
  id: number;

  invoiceNumber: string;

  @Type(() => Number)
  status: InvoiceStatus;

  @Type(() => UserDto)
  manager: UserDto;

  @Type(() => ContractInfoDto)
  contract: ContractInfoDto;

  @Type(() => CompanyDto)
  sellerCompany: CompanyDto;

  @Type(() => LegalEntityDto)
  seller: LegalEntityDto;

  @Type(() => CompanyDto)
  buyerCompany: CompanyDto;

  @Type(() => LegalEntityDto)
  buyer: LegalEntityDto;

  totals: InvoiceTotals;

  @IsBoolean()
  printFormShowMediaLink: boolean;

  @IsNumber()
  @IsIn([INVOICE_PRINT_NOT_SHOW_DATE,INVOICE_PRINT_SHOW_MONTH,INVOICE_PRINT_SHOW_ALL_DATE])
  printFormShowDateViewState: number;

  static fromPlain(obj: any) { return plainToInstance<InvoiceDto, any>(InvoiceDto, obj); }

  appendix: ContractAppendixDto

  legalEntityTax: number
}


export class UpdateInvoiceResource extends InvoiceBasicResource { }

export class InvoiceCanceledStatusDto {
  @IsBoolean()
  isCanceled: boolean;
}

export class SetInvoiceLegalEntityTaxDto {
  @IsNumber()
  @Max(100)
  @Min(0)
  @IsNotEmpty()
  legalEntityTax: number;
}

