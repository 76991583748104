import { Field } from 'formik';
import { UserPickerField } from 'features/user/user-picker/ui';
import { BaseUrlParamsFilter, BaseUrlParamsFilterProps } from './base-url-params-filter';

export const ManagerUrlParamsFilter = ({ queryParamName, label, size = 'medium', withNonSelect }: BaseUrlParamsFilterProps) => {
  return (
    <BaseUrlParamsFilter queryParamName={queryParamName}>
      <Field
        fullWidth
        name={queryParamName}
        label={label}
        clearable={true}
        size={size}
        withNonSelectOption={withNonSelect}
        component={UserPickerField}
      />
    </BaseUrlParamsFilter>
  );
};

