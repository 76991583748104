import { FormikErrors } from 'formik';
import {
  StringifiedBoolean,
  LegalEntityOurDataDto,
  LegalEntityOurDataMultipartFormCreateDto, LegalEntityOurDataMultipartFormUpdateDto
} from '@sr/dto';
import { fileFieldToDto, FormDataFileField, FormTools } from 'utils/form-tools';

export type LegalEntityOurDataFormikFormData = {
  isClientInvoicesEnabled: boolean;
  isSupplierInvoicesEnabled: boolean;
  invoicePrefix: string,
  legalEntityTax: number,
  stamp: FormDataFileField,
  sellerSignerId: number | null
}

const toCreateDto = (formData:LegalEntityOurDataFormikFormData): LegalEntityOurDataMultipartFormCreateDto => ({
  isClientInvoicesEnabled: formData.isClientInvoicesEnabled ? StringifiedBoolean.TRUE: StringifiedBoolean.FALSE,
  isSupplierInvoicesEnabled: formData.isSupplierInvoicesEnabled ? StringifiedBoolean.TRUE: StringifiedBoolean.FALSE,
  invoicePrefix: formData.invoicePrefix,
  legalEntityTax: Number(formData.legalEntityTax),
  stamp: fileFieldToDto(formData.stamp),
  sellerSignerId: formData.sellerSignerId
});

export const formTools: FormTools<LegalEntityOurDataDto, LegalEntityOurDataMultipartFormCreateDto, LegalEntityOurDataMultipartFormUpdateDto, LegalEntityOurDataFormikFormData> = {
  fromDto: (dto: LegalEntityOurDataDto): LegalEntityOurDataFormikFormData => ({
    isClientInvoicesEnabled: dto.isClientInvoicesEnabled,
    isSupplierInvoicesEnabled: dto.isSupplierInvoicesEnabled,
    invoicePrefix: dto.invoicePrefix,
    legalEntityTax: dto.legalEntityTax,
    stamp: dto.stamp ?? null,
    sellerSignerId: dto.sellerSignerId,
  }),
  toCreateDto,
  toUpdateDto: (id, formData) => ({
    ...toCreateDto(formData),
    id,
  }),
  validate: (values):FormikErrors<LegalEntityOurDataFormikFormData>=>{
    let validationResult: FormikErrors<LegalEntityOurDataFormikFormData> = {};
    if (values.invoicePrefix.length > 8) {
      validationResult.invoicePrefix = 'Максимум 8 символов';
    }
    if (values.invoicePrefix.length < 1) {
      validationResult.invoicePrefix = 'Префикс не может быть пустым';
    }
    const tax = Number(values.legalEntityTax);
    if (isNaN(tax) || tax < 0 || tax > 100) validationResult.legalEntityTax = 'Значение налога должно быть числом от 0 до 100';
    return validationResult;
  },
  emptyValues: (): LegalEntityOurDataFormikFormData => ({
    isClientInvoicesEnabled: true,
    isSupplierInvoicesEnabled: true,
    invoicePrefix: '',
    legalEntityTax: 0,
    stamp: null,
    sellerSignerId: null,
  })
};
