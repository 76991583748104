import { TextField } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';

type Props = {
  initialValue: number;
  readOnly: boolean;
  onChange: (value: number) => void;
}

export default function PercentageInput ({ initialValue, onChange, readOnly }: Props) {
  const [error, setError] = useState('');
  const [value, setValue] = useState<number|''>(initialValue);

  const handlePercentageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.value || isNaN(Number(event.target.value))) {
      setValue('');
      return;
    }
    const percentage = Number(event.target.value);
    if (percentage > 100) {
      setError('Больше 100%');
      setValue(percentage);
      return;
    }
    setError('');
    setValue(percentage);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (value === '' || value === initialValue || error) {
      return;
    }
    const timeout = setTimeout(()=> {
      onChange(value);
    }, 500);
    return ()=>{ clearTimeout(timeout) };
  }, [ value ]);

  return (
    <TextField
      value={value}
      disabled={readOnly}
      onChange={handlePercentageChange}
      size='small'
      label='%'
      helperText={error}
      error={!!error}
      fullWidth/>
  );
}
