import { useClientInvoices } from '../../../entities/client-invoices-list/lib/client-invoices-list.hook';
import { usePagingParams } from '../../../shared/hooks/paging-params';
import { useSearchParams } from 'react-router-dom';
import { INVOICE_STATUS_PAID } from '@sr/dto';
import { InvoicesList } from '../../../entities/invoice/list';

export const CloseInvoices = () => {
  const [paging, setPaging] = usePagingParams();
  const [searchParams] = useSearchParams();

  const invoicesSearchParams = `managerId=${searchParams.get('manager')}&statusIds=${INVOICE_STATUS_PAID}&closingYear=${searchParams.get('year')}&closingMonth=${searchParams.get('month')}`;

  const { collection } = useClientInvoices(paging, invoicesSearchParams);

  return <InvoicesList collection={collection} onChangePaging={setPaging} paging={paging} withSalaryEntries />;
};
