import { FieldProps } from 'formik';
import { useLeadStatuses } from '../../../entities/lead/lead-status';
import { CollectionMultiSelect } from '../collection-multiple-autocomplete';
import { CollectionAutocomplete } from '../collection-autocomplete';

export const LeadStatusPickerField = ({ form, field, meta, multi = false }: FieldProps<number, any> & {multi?: boolean}) => {
  const statuses = useLeadStatuses();
  return (
    multi ? (<CollectionMultiSelect
      field={field}
      form={form}
      isLoading={statuses.isLoading}
      items={statuses.items}
      clearable={true}
      label = {'Статус'}
      meta = {meta}/>) :
      (<CollectionAutocomplete field={field}
        form={form}
        isLoading={statuses.isLoading}
        items={statuses.items}
        clearable={true}
        label = {'Статус'}
        meta = {meta}/>)

  );
};
