import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { invoicesListReducer } from 'entities/client-invoices-list/model/invoices-list.slice';
import { companyReducer } from 'entities/company/details/company.slice';
import { linkedManagersReducer } from 'entities/company/linked-managers/linked-managers.slice';
import { invoiceMutableDataReducer } from 'entities/invoice/mutable-data/invoice-mutable-data.slice';
import { userProfileReducer } from 'entities/user/profile/user-profile.slice';
import { authReducer } from 'features/auth/auth-slice';
import { changePasswordReducer } from 'features/user/change-password-dialog';
import { userApi } from 'entities/user/api';
import { timeSheetApi } from 'features/salary/time-sheet/time-sheet.api';
import { salaryPagePeriodReducer } from 'entities/salary/salary-period/salary-period.slice';
import { companiesApi } from 'entities/company/api.slice';
import { cityReducer } from 'entities/geo/cities/cityUiSlice';
import { mediaReducer } from 'entities/media/media-ui-slice';
import { mediaApi } from 'entities/media/api-slice';
import { cityApi } from 'entities/geo/cities/cityApiSlice';
import { wccBulkCreateReducer } from 'features/wcc-bulk-create/slice';
import { wccBulkCreateApi } from 'features/wcc-bulk-create/api';
import { wccApi } from 'entities/wcc/api';
import { wccExportReducer } from 'features/wcc-export';
import { invoiceApi } from 'entities/invoice/api-slice';
import { printInvoiceReducer } from 'widgets/invoice/print-form';
import { mediaTypesApi } from 'entities/media/media-types';
import { contactsApi } from 'features/contact/contacts.api-slice';
import { publishingDataApi } from 'entities/publishing-data/api-slice';
import { leadApi } from 'features/lead/api';
import { contactDupesApi } from 'entities/contact-dupes/api.slice';
import { dupesReducer } from 'entities/contact-dupes/store.slice';
import { eventsApi } from 'features/events/api';
import { searchApi } from 'entities/search/api.slice';
import { salaryApi } from 'entities/salary/salary.api';
import { legalEntitiesApi } from 'entities/legal-entities/legal-entities.api';
import { userSalaryReducer } from 'entities/salary/salary-user-picker.slice';
import { salaryDialogReducer } from 'entities/salary/salary-entry-dialog/salary-entry.slice';
import { cashBalancePeriodReducer } from 'entities/reports/cash-balance/cash-balance-period/cash-balance-period.slice';
import { reportsApi } from 'entities/reports/reports.api';
import { purchaseApi } from 'entities/purchase/purchase.api';
import { invoiceRowsApi } from 'entities/invoice-rows/invoice-rows.api';
import { invoiceReportApi } from 'entities/invoice-report/invoice-report.api';
import { legalEntitiesTypeApi } from 'entities/legal-entities/legal-entities-type/legal-entities-type.api';
import { payOrdersApi } from 'entities/pay-orders/pay-orders.api';
import { userRolesApi } from '../../entities/user/user-roles';
import { prepaymentDialogReducer } from 'entities/prepayment/prepayment-dialog/prepayment-dialog.slice';

import { parsedEmailApi } from '../../features/parsed-emails/api';
import { invoiceCloseApi } from '../../entities/invoice-report/invoice-close/invoice-close.api';
import { emailParserApi } from 'email-parser/email-parser.api';
import { invoiceWccApi } from 'features/invoice/invoice-wcc/invoice-wcc.api';

export const rootReducer = combineReducers({
  auth: authReducer,
  companyDetails: companyReducer,
  companyLinkedManagers: linkedManagersReducer,
  // companyContacts: ....,
  // companyLegalEntities: ....,
  // companyContracts: ....,

  // legalEntityDetails: ....,
  // legalEntityBankDetails: ....,
  // legaEntitySigners: ....,

  invoicesList: invoicesListReducer,
  invoiceMutableData: invoiceMutableDataReducer,
  user: userProfileReducer,
  wcc: combineReducers({
    bulkCreate: wccBulkCreateReducer,
    export: wccExportReducer,
  }),
  [companiesApi.reducerPath]: companiesApi.reducer,
  [invoiceApi.reducerPath]: invoiceApi.reducer,
  [invoiceRowsApi.reducerPath]: invoiceRowsApi.reducer,
  [invoiceReportApi.reducerPath]: invoiceReportApi.reducer,
  [wccApi.reducerPath]: wccApi.reducer,
  [invoiceWccApi.reducerPath]: invoiceWccApi.reducer,
  [payOrdersApi.reducerPath]: payOrdersApi.reducer,
  [wccBulkCreateApi.reducerPath]: wccBulkCreateApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [userRolesApi.reducerPath]: userRolesApi.reducer,
  [mediaApi.reducerPath]: mediaApi.reducer,
  [contactsApi.reducerPath]: contactsApi.reducer,
  [cityApi.reducerPath]: cityApi.reducer,
  [mediaTypesApi.reducerPath]: mediaTypesApi.reducer,
  [leadApi.reducerPath]: leadApi.reducer,
  [publishingDataApi.reducerPath]: publishingDataApi.reducer,
  [contactDupesApi.reducerPath]: contactDupesApi.reducer,
  [searchApi.reducerPath]: searchApi.reducer,
  [eventsApi.reducerPath]: eventsApi.reducer,
  [timeSheetApi.reducerPath]: timeSheetApi.reducer,
  [salaryApi.reducerPath]: salaryApi.reducer,
  [invoiceCloseApi.reducerPath]: invoiceCloseApi.reducer,
  [purchaseApi.reducerPath]: purchaseApi.reducer,
  [legalEntitiesApi.reducerPath]: legalEntitiesApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [parsedEmailApi.reducerPath]: parsedEmailApi.reducer,
  cashBalanceSelectedPeriod: cashBalancePeriodReducer,
  salaryPageSelectedPeriod: salaryPagePeriodReducer,
  salaryUserSelect: userSalaryReducer,
  ui: combineReducers({
    changePasswordDialog: changePasswordReducer,
    city: cityReducer,
    media: mediaReducer,
    printInvoice: printInvoiceReducer,
    dupesIndication: dupesReducer,
    salaryDialog: salaryDialogReducer,
    prepaymentDialog: prepaymentDialogReducer,
  }),
  [legalEntitiesTypeApi.reducerPath]: legalEntitiesTypeApi.reducer,
  [emailParserApi.reducerPath]: emailParserApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      companiesApi.middleware,
      userApi.middleware,
      userRolesApi.middleware,
      timeSheetApi.middleware,
      mediaApi.middleware,
      mediaTypesApi.middleware,
      contactsApi.middleware,
      publishingDataApi.middleware,
      cityApi.middleware,
      invoiceApi.middleware,
      invoiceRowsApi.middleware,
      invoiceReportApi.middleware,
      wccBulkCreateApi.middleware,
      wccApi.middleware,
      invoiceWccApi.middleware,
      payOrdersApi.middleware,
      leadApi.middleware,
      contactDupesApi.middleware,
      searchApi.middleware,
      purchaseApi.middleware,
      salaryApi.middleware,
      invoiceCloseApi.middleware,
      reportsApi.middleware,
      legalEntitiesApi.middleware,
      eventsApi.middleware,
      legalEntitiesTypeApi.middleware,

      parsedEmailApi.middleware,
      emailParserApi.middleware
    ]),
});
