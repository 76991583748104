import { LeadStatusDto } from '@sr/dto';
import { leadBaseApi } from 'entities/lead/api';
import { LEAD_BASE_URL } from 'shared/api/api';

const injectedApi = leadBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    updateLeadManager: builder.mutation<LeadStatusDto, { leadId: number; managerId: number | null }>({
      query: ({ leadId, managerId }) => ({
        url: `${LEAD_BASE_URL}/${leadId}/manager`,
        body: { managerId },
        method: 'PUT',
      }),
    }),
  }),
});

export const leadManagerApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['lead'],
  endpoints: {
    updateLeadManager: {
      invalidatesTags: ['lead'],
    },
  },
});
